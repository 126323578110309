import GridView from "components/GridView";
import useGridStore from "components/GridView/grid.store";
import { GridSchema, GridViewHeaderProps } from "components/GridView/GridView.types";
import { useCookieContext } from "contexts";
import { useBreadcrumb } from "contexts/breadcrumb/BreadcrumbContext";
import { useNotification } from "hooks/useNotification";
import useSystemDateTheme from "hooks/useSystemDateTheme";
import { useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { ProgramsService } from "services/programs";
import { ProgramLocalResult } from "services/programs/models/ProgramTypes";
import { useShallow } from "zustand/react/shallow";

const PublicProgramsGridView = () => {
  const ready = useRef(false);
  const { setBreadcrumbs } = useBreadcrumb();
  const { t } = useTranslation("Programs");
  const { toSystemThemeDateFormat } = useSystemDateTheme();
  const [loading, setLoading] = useState(false);
  const { showNotification } = useNotification();
  const [programs, setPrograms] = useState<ProgramLocalResult[] | undefined>();
  const [totalCount, setTotalCount] = useState(0);
  const { isAr } = useCookieContext();

  const { sortState, pagination, setPagination, searchValue, filters, resetGridStore } = useGridStore(
    useShallow((state) => ({
      sortState: state.sortState,
      pagination: state.pagination,
      setPagination: state.setPagination,
      searchValue: state.generalSearch,
      filters: state.filters,
      resetGridStore: state.reset,
    })),
  );

  const fetchPrograms = async ({ resetPagination = false }: { resetPagination?: boolean } = {}) => {
    try {
      setLoading(true);
      const response = await ProgramsService.getAllPublicPrograms({
        pageIndex: resetPagination ? 1 : pagination.currentPage,
        pageSize: 10,
        search: searchValue,
        sort: sortState
          ? Object.keys(sortState).map((key) => ({
              field: key,
              dir: sortState[key],
            }))
          : [],
        filter: Object.keys(filters)
          .filter((key) => filters[key])
          .map((key) => ({
            field: key,
            value: filters[key],
            operator: "Contains",
          })),
      });

      setPrograms(response.data.result);
      setTotalCount(response.data.totalCount);

      if (resetPagination) {
        setPagination({
          currentPage: 1,
          totalPages: Math.ceil(response.data.totalCount / 10),
        });
      }
    } catch (error) {
      showNotification({
        type: "error",
        description: t("Common:internalServerError"),
      });
      setPagination({
        currentPage: 1,
        totalPages: 0,
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!ready.current) {
      return;
    }
    fetchPrograms();
  }, [pagination.currentPage]);

  useEffect(() => {
    if (!ready.current) {
      return;
    }
    fetchPrograms({ resetPagination: true });
  }, [sortState, searchValue, filters, isAr]);

  useEffect(() => {
    if (!ready.current) {
      resetGridStore();
      ready.current = true;
      return;
    }
  }, [resetGridStore]);

  useEffect(() => {
    setBreadcrumbs([
      { localizationKey: "publicPrograms", path: "/home/programs/public", menuItemId: "publicPrograms" },
    ]);
  }, []);

  const gridSchema = useMemo<GridSchema[]>(
    () => [
      {
        field: "SerialNumber",
        isHidden: true,
        filterable: true,
        displayName: t("serialNumber"),
      },
      {
        field: "ProgramDetail.DisplayName",
        displayName: t("programName"),
        render: (row: ProgramLocalResult) => {
          return <Link to={`/programDetails/${row.id}`}>{row.programDetail.displayName}</Link>;
        },
      },
      {
        accessor: "programType",
        field: "ProgramType",
        displayName: t("programType"),
      },
      {
        field: "AdsEndDate",
        displayName: t("adsClose"),
        sortable: true,
        filterable: true,
        filterOptions: {
          type: "date",
        },
        render: (row: ProgramLocalResult) => {
          return <>{toSystemThemeDateFormat(row.adsEndDate)}</>;
        },
      },
      {
        accessor: "fundingDurationInMonths",
        field: "FundingDurationInMonths",
        displayName: t("fundingDuration"),
        sortable: true,
        filterable: true,
      },
      {
        field: "actions",
        displayName: t("Common:actions"),
        sortable: false,
        render: (row: ProgramLocalResult) => {
          return (
            <>
              <Link to={`/home/orders/add?programId=${row.id}`} className="btn text-darkGray btn-sm">
                <i className="icon-orders" data-bs-toggle="tooltip" data-bs-placement="top" title={t("applyOrder")} />
              </Link>
            </>
          );
        },
      },
    ],
    [t, toSystemThemeDateFormat],
  );

  const viewHeader: GridViewHeaderProps = {
    title: t("allPrograms"),
    singularName: t("program"),
    totalCount: totalCount,
    // TODO: endpoint to export public programs
    onExportClick: async () => {
      //   setLoading(true);
      //   const response = await ProgramsService.exportProgramsAsync({
      //     pageIndex: pagination.currentPage,
      //     pageSize: 10,
      //     search: searchValue,
      //   });
      //   const blob = new Blob([response.data], { type: response.headers["content-type"] });
      //   const link = document.createElement("a");
      //   link.href = window.URL.createObjectURL(blob);
      //   link.download = "Programs.xlsx";
      //   document.body.appendChild(link);
      //   link.click();
      //   setLoading(false);
      //   link.remove();
    },
  };

  return (
    <GridView
      loading={loading}
      gridProps={{
        data: programs ?? [],
        gridSchema,
      }}
      viewHeaderProps={viewHeader}
    />
  );
};

export default PublicProgramsGridView;
