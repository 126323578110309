import Grid from "components/GridView/components/Grid";
import AddExaminerModal from "../AddExaminerModal";
import { MouseEvent, useMemo, useState } from "react";
import { GridSchema } from "components/GridView/GridView.types";
import { useTranslation } from "react-i18next";
import IconButton from "components/blocks/IconButton";
import { useQuery } from "hooks/useQuery";
import { ExaminersService } from "services/examiners";
import { ExaminerListResult } from "services/examiners/Model/ExaminerTypes";
import ReferExaminerModal from "../ReferExaminersModal";
import { DeleteExaminerModal, DeleteExaminerModalProps } from "./DeleteExaminerModal";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import Dropdown from "components/blocks/Dropdown";
import { ExaminerSelectedTemplateType } from "..";
import ViewExaminerEvaluation, { ViewExaminerEvaluationProps } from "./ViewExaminerEvaluation";

const EXAMINER_TASK_STATUS = {
  Pending: "examinerTaskPending",
  Cancel: "examinerTaskCancel",
  Apologies: "examinerTaskApologies",
  Returned: "examinerTaskReturned",
  Submitted: "examinerTaskSubmitted",
  Evaluated: "examinerTaskEvaluated",
  Accepted: "examinerTaskAccepted",
};
type ExaminerTaskStatus = keyof typeof EXAMINER_TASK_STATUS;

const ExaminersTable = ({
  orderId,
  programOrderEvaluationId,
  selectedTemplate,
}: {
  orderId: number;
  programOrderEvaluationId: number;
  selectedTemplate: ExaminerSelectedTemplateType;
}) => {
  const { t } = useTranslation(["Orders"]);
  const [triggerTableUpdate, setTriggerTableUpdate] = useState(false);
  const [checkedExaminerToReferer, setCheckedExaminerToReferer] = useState<ExaminerListResult[]>([]);
  const [isAddExaminerModalOpened, setIsAddExaminerModalOpened] = useState(false);
  const [isRefererExaminersModalOpened, setIsRefererExaminersModalOpened] = useState(false);
  const [viewExaminerEvaluationModalProps, setViewExaminerEvaluationModalProps] =
    useState<ViewExaminerEvaluationProps | null>(null);
  const [deleteExaminerModalProps, setDeleteExaminerModalProps] = useState<
    DeleteExaminerModalProps | null | undefined
  >();

  const { data: selectedExaminers } = useQuery({
    queryFn: async () => {
      return await ExaminersService.getAllExaminersByOrderId(orderId);
    },
    triggers: [orderId, triggerTableUpdate],
    options: {
      enabled: !!orderId,
    },
  });

  const onCheckExaminer = (e: MouseEvent<HTMLInputElement, globalThis.MouseEvent>, userId: string | number) => {
    if (e.currentTarget.checked) {
      setCheckedExaminerToReferer((old) => [...old, selectedExaminers!.find((examiner) => examiner.id === userId)!]);
    } else {
      setCheckedExaminerToReferer((old) => old.filter((examiner) => examiner.id !== userId));
    }
  };

  const onSelectAllNewExaminer = (e: MouseEvent<HTMLInputElement, globalThis.MouseEvent>) => {
    if (e.currentTarget.checked) {
      setCheckedExaminerToReferer(selectedExaminers?.filter((examiner) => !examiner.status) ?? []);
    } else {
      setCheckedExaminerToReferer([]);
    }
  };

  const onDeleteExaminer = (row: ExaminerListResult) => {
    setDeleteExaminerModalProps({
      isOpen: true,
      onClose: (shouldUpdateExaminersTable: boolean) => {
        setDeleteExaminerModalProps(null);
        if (shouldUpdateExaminersTable) {
          setTriggerTableUpdate(!triggerTableUpdate);
        }
      },
      selectedExaminerId: row.id,
      examinerStatus: row.status,
      examinerName: row.examinerName,
    });
  };

  const onViewExaminerEvaluation = (row: ExaminerListResult) => {
    setViewExaminerEvaluationModalProps({
      evaluationPercentage: row.evaluationPercentage,
      onClose: () => {
        setViewExaminerEvaluationModalProps(null);
      },
      examinerEvaluationFormValue: row.examinerEvaluationFormValue,
      selectedTemplate: selectedTemplate,
    });
  };

  const gridSchema = useMemo<GridSchema[]>(
    () => [
      {
        accessor: "",
        field: "",
        displayName: "",
        render: (row: ExaminerListResult) => {
          return (
            <input
              type="checkbox"
              disabled={!!row.status}
              checked={checkedExaminerToReferer.some((examiner) => examiner.id === row.id)}
              onClick={(e) => {
                onCheckExaminer(e, row.id);
              }}
            />
          );
        },
        renderHeader: () => {
          return <input type="checkbox" onClick={onSelectAllNewExaminer} />;
        },
      },
      {
        field: "examinerName",
        displayName: t("examinerName"),
        render: (row: ExaminerListResult) => {
          return <span className={row.status === "Cancel" ? "text-overline" : ""}>{row.examinerName}</span>;
        },
      },
      {
        field: "round",
        displayName: t("round"),
        render: (row: ExaminerListResult) => {
          const isDelayed =
            row.deadLine && row.status !== "Evaluated" ? new Date().getTime() > new Date(row.deadLine).getTime() : true;
          return (
            <div className="d-flex justify-content-between">
              <span>{row.iteration}</span>
              {row.status === "Pending" && row.isSentInvitation && (
                <OverlayTrigger placement="top" overlay={<Tooltip>{t("notRegisteredWithSentInvitation")}</Tooltip>}>
                  <i className="icon-help text-primary"></i>
                </OverlayTrigger>
              )}
              {isDelayed && <i className="icon-warning text-danger"></i>}
            </div>
          );
        },
      },
      {
        field: "evaluationPercentage",
        displayName: t("evaluation"),
        render: (row: ExaminerListResult) => {
          return <>{row.evaluationPercentage ? `${row.evaluationPercentage}%` : "-"}</>;
        },
      },
      {
        field: "recommendations",
        displayName: t("recommendations"),
        render: (row) => {
          return row.recommendations;
        },
      },
      {
        field: "taskStatus",
        displayName: t("taskStatus"),
        render: (row) => {
          return row.status ? t(EXAMINER_TASK_STATUS[row.status as ExaminerTaskStatus]) : "-";
        },
      },
      {
        field: "confirmExaminersResult",
        displayName: t("confirmExaminersResult"),
        render: (row) => {
          return row.status === "Evaluated" ? (
            <Dropdown
              data={[
                { id: "1", value: t("approveExaminerResult") },
                { id: "2", value: t("rejectExaminerResult") },
              ]}
            />
          ) : (
            "-"
          );
        },
      },
      {
        field: "actions",
        displayName: t("actions"),
        render: (row: ExaminerListResult) => {
          const isDeleteIconShown = row.status === "Evaluated" || row.status !== "Cancel";

          return (
            <div className="d-flex gap-2">
              <IconButton
                icon="icon-view"
                fitToIconWidth
                innerIconColor="primary"
                size="md"
                onClick={() => {
                  onViewExaminerEvaluation(row);
                }}
              />
              {isDeleteIconShown && (
                <IconButton
                  icon="icon-delete"
                  innerIconColor="danger"
                  size="md"
                  onClick={() => onDeleteExaminer(row)}
                />
              )}
            </div>
          );
        },
      },
    ],
    [checkedExaminerToReferer, selectedExaminers],
  );

  return (
    <>
      <div className="d-flex justify-content-between align-items-center">
        <h4>{t("evaluations")}</h4>
        <div>
          <button
            className="btn btn-primary p-10-32P rounded-4"
            onClick={() => {
              setIsAddExaminerModalOpened(true);
            }}
          >
            {t("addExaminers")}
          </button>
          {!!checkedExaminerToReferer?.length && (
            <button
              className="btn border me-2 p-10-32P rounded-4 text-dark"
              onClick={() => {
                setIsRefererExaminersModalOpened(true);
              }}
            >
              {t("referExaminers")}
            </button>
          )}
        </div>
      </div>

      {!!selectedExaminers?.length && <Grid data={selectedExaminers} gridSchema={gridSchema} />}
      <AddExaminerModal
        isOpen={isAddExaminerModalOpened}
        programOrderEvaluationId={programOrderEvaluationId}
        onClose={(shouldUpdateTable?: boolean) => {
          setIsAddExaminerModalOpened(false);
          if (shouldUpdateTable) {
            setTriggerTableUpdate(!triggerTableUpdate);
          }
        }}
      />

      <ReferExaminerModal
        isOpen={isRefererExaminersModalOpened}
        checkedExaminerToReferer={checkedExaminerToReferer}
        onClose={() => {
          setIsRefererExaminersModalOpened(false);
          setCheckedExaminerToReferer([]);
          setTriggerTableUpdate(!triggerTableUpdate);
        }}
      />

      <DeleteExaminerModal props={deleteExaminerModalProps} />
      <ViewExaminerEvaluation props={viewExaminerEvaluationModalProps} />
    </>
  );
};

export default ExaminersTable;
