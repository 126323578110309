import LoadingOverlay from "components/blocks/LoadingOverlay";
import { useTranslation } from "react-i18next";
import { UserInformationResult } from "services/accountService/models/AccountType";

const PersonalInfo = ({ user }: { user?: UserInformationResult | null }) => {
  const { t } = useTranslation("UserProfile");

  return (
    <div className="striped d-flex flex-column position-relative ">
      <div className="d-flex flex-1 py-4 pe-4">
        <div className="flex-1">{t("nationalId")}</div>
        <div className="flex-2">{user?.nationalId}</div>
      </div>

      <div className="d-flex flex-1 py-4 pe-4">
        <div className="flex-1">{t("phoneNumber")}</div>
        <div className="flex-2">{user?.phoneNumber}</div>
      </div>

      <div className="d-flex flex-1 py-4 pe-4">
        <div className="flex-1">{t("email")}</div>
        <div className="flex-2">{user?.email}</div>
      </div>

      <div className="d-flex flex-1 py-4 pe-4">
        <div className="flex-1">{t("nationality")}</div>
        <div className="flex-2">{user?.nationality}</div>
      </div>

      <div className="d-flex flex-1 py-4 pe-4">
        <div className="flex-1">{t("gender")}</div>
        <div className="flex-2">{user?.gender}</div>
      </div>

      <div className="d-flex flex-1 py-4 pe-4">
        <div className="flex-1">{t("birthDate")}</div>
        <div className="flex-2">{user?.birthDate}</div>
      </div>
    </div>
  );
};

export default PersonalInfo;
