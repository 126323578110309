import { GridSchema } from "components/GridView/GridView.types";
import Grid from "components/GridView/components/Grid";
import Accordion from "components/blocks/Accordion";
import AccordionItem from "components/blocks/Accordion/AccordionItem";
import AttachmentCard from "components/blocks/AttachmentCard";
import FilePreview from "components/blocks/FileInput/FilePreview";
import IconButton from "components/blocks/IconButton";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

type ResearchesCenterSectionProps = {
  centerDate: {
    evaluation: number;
    recommendations: string;
    checkerName: string;
    checkerResultApproval: string;
  }[];
  attachment: {
    id: number;
    fileName: string;
    size: string;
    extension: string;
  };
  evaluationForm: {
    id: number;
    fileName: string;
    size: number;
    extension: string;
  };
  note: string;
};

const ResearchesCenter = () => {
  const { t } = useTranslation("Orders");

  const researchesCenters: ResearchesCenterSectionProps = {
    centerDate: [
      {
        evaluation: 85,
        recommendations: "توصيات",
        checkerName: "اسم المدقق",
        checkerResultApproval: "موافقة نتيجة المدقق",
      },
      {
        evaluation: 85,
        recommendations: "توصيات",
        checkerName: "اسم المدقق",
        checkerResultApproval: "موافقة نتيجة المدقق",
      },
    ],
    attachment: {
      id: 1,
      fileName: "اسم الملف.pdf",
      size: "1MB",
      extension: "pdf",
    },
    evaluationForm: {
      id: 1,
      fileName: "اسم الملف.pdf",
      size: 1024 * 1024,
      extension: "pdf",
    },
    note: "لوريم ايبسوم دولار سيت أميت ,كونسيكتيتور أدايبا يسكينج أليايت,سيت دو أيوسمود تيمبور أنكايديديونتيوت لابوري ات دولار ماجنا أليكيوا . يوت انيم أد مينيم فينايم,كيواس نوستريد أكسير سيتاشن يللأمكو لابورأس نيسي يت أليكيوب أكس أيا كوممودو كونسيكيوات . ديواس",
  };

  const researchesCentersTableHeaders = useMemo<GridSchema[]>(
    () => [
      {
        accessor: "checkerName",
        field: "checkerName",
        displayName: t("checkerName"),
        columnClassName: "w-50",
      },
      {
        accessor: "evaluation",
        field: "evaluation",
        displayName: t("evaluation"),
        render: (row: ResearchesCenterSectionProps["centerDate"][0]) => {
          return <span>{row.evaluation}%</span>;
        },
      },
      {
        accessor: "recommendations",
        field: "recommendations",
        displayName: t("recommendations"),
      },

      {
        accessor: "checkerResultApproval",
        field: "checkerResultApproval",
        displayName: t("acceptExaminerResults"),
      },
      {
        field: "actions",
        displayName: t("actions"),
        render: (row: ResearchesCenterSectionProps["centerDate"][0]) => {
          return (
            <div className="d-flex ">
              <IconButton icon="icon-view" variant="transparent" innerIconColor="primary" onClick={() => {}} />
            </div>
          );
        },
      },
    ],
    [],
  );

  const gridFooter = useMemo(() => {
    return [
      [
        {
          content: <span className="fw-bold p-2">{t("total")}</span>,
        },
        {
          content: (
            <span className="fw-bold p-2">
              {researchesCenters.centerDate.reduce((acc, centerDate) => (acc += Number(centerDate.evaluation)), 0) /
                researchesCenters.centerDate.length}
              %
            </span>
          ),
        },
        {
          content: "",
          colSpan: 3,
        },
      ],
    ];
  }, []);

  return (
    <Accordion>
      <AccordionItem initiallyOpen title={t("researchesCenter")} elementId={5}>
        <div className="d-flex flex-column gap-2 pb-2 border-bottom border-1">
          <Grid
            data={researchesCenters.centerDate}
            gridSchema={researchesCentersTableHeaders}
            gridFooter={gridFooter}
          />
          <div className="align-self-start">
            <AttachmentCard
              attachment={{
                id: researchesCenters.attachment.id,
                fileName: researchesCenters.attachment.fileName,
                size: researchesCenters.attachment.size,
                extension: researchesCenters.attachment.extension,
              }}
            />
          </div>
          <div className="d-flex flex-column my-2">
            <h6 className="fw-bold">{t("evaluationForm")}</h6>
            <FilePreview
              file={new File([""], researchesCenters.evaluationForm.fileName, { type: "application/pdf" })}
              onShow={() => {}}
            />
          </div>
          <div className="my-2">
            <h6 className="fw-bold">{t("notes")}</h6>
            <div className="fs-12px ">{researchesCenters.note}</div>
          </div>
        </div>
      </AccordionItem>
    </Accordion>
  );
};

export default ResearchesCenter;
