import { useTranslation } from "react-i18next";
import { GridFilterProps } from "components/GridView/GridView.types";
import DatePicker from "components/blocks/DatePicker";
import useGridStore from "components/GridView/grid.store";
import { useShallow } from "zustand/react/shallow";
import Dropdown from "components/blocks/Dropdown";
import FormInput from "components/blocks/FormInput";
import IconButton from "components/blocks/IconButton";
import { useMemo, useState } from "react";

const GridFilter: React.FC<GridFilterProps> = ({ gridSchema }) => {
  const { t } = useTranslation("Common");
  const { modelFilterPined, toggleModelFilterPin, modelFilterVisible, setFilters } = useGridStore(
    useShallow((state) => ({
      modelFilterPined: state.modelFilterPined,
      toggleModelFilterPin: state.toggleModelFilterPin,
      modelFilterVisible: state.modelFilterVisible,
      filters: state.filters,
      setFilters: state.setFilters,
    })),
  );

  const [gridFilters, initialState] = useMemo(() => {
    const f = gridSchema
      .filter((schema) => schema.filterable)
      .map((schema) => ({
        field: schema.field,
        filterDisplayValue: schema.filterDisplayValue,
        displayName: schema.displayName,
        inputType: schema.filterOptions?.type || "text",
        options: schema.filterOptions?.options || [],
      }));
    const s = f.reduce((acc, filter) => ({ ...acc, [filter.field]: "" }), {});

    return [f, s];
  }, [gridSchema]);

  const [localFilters, setLocalFilters] = useState<Record<string, any>>(initialState);

  const onValueChange = (filed: string, value: any) => {
    setLocalFilters((prev) => ({ ...prev, [filed]: value }));
  };

  const onSubmit = () => {
    setFilters(localFilters);
  };

  const onReset = () => {
    setLocalFilters(initialState);
    setFilters({});
  };

  return (
    <div
      className={`w-100 my-3 d-flex justify-content-between align-items-end flex-wrap ${
        modelFilterPined || modelFilterVisible ? "" : "d-none"
      }`}
    >
      <div className="d-flex flex-wrap gap-3 align-items-end">
        {gridFilters.map((filter) => {
          if (filter.inputType === "text") {
            return (
              <FormInput
                key={filter.displayName}
                label={filter.filterDisplayValue || filter.displayName}
                placeholder={filter.filterDisplayValue || filter.displayName}
                value={localFilters[filter.field]}
                onChange={(e) => {
                  onValueChange(filter.field, e.currentTarget.value);
                }}
              />
            );
          } else if (filter.inputType === "select") {
            return (
              <Dropdown
                key={filter.displayName}
                wrapperClassName="flex-grow-1"
                label={filter.filterDisplayValue || filter.displayName}
                noSelectionPlaceholder={filter.filterDisplayValue || filter.displayName}
                value={localFilters[filter.field]}
                data={filter.options}
                onChange={(value) => {
                  onValueChange(filter.field, value);
                }}
              />
            );
          } else if (filter.inputType === "date") {
            return (
              <DatePicker
                key={filter.displayName}
                wrapperClassName="flex-grow-1"
                placeholder={filter.filterDisplayValue || filter.displayName}
                label={filter.filterDisplayValue || filter.displayName}
                value={localFilters[filter.field]}
                onChange={(date) => {
                  if (date) {
                    const datePart = new Date(date).toISOString().split("T")[0];
                    onValueChange(filter.field, datePart);
                  }
                }}
              />
            );
          } else {
            return null;
          }
        })}
      </div>
      <div className="form-group d-flex gap-2 align-items-end mt-2">
        <button type="button" className="btn btn-outline-primary p-2" onClick={onSubmit}>
          {t("filterNow")}
        </button>
        <button type="button" className="btn btn-outline-danger p-2" onClick={onReset}>
          {t("reset")}
        </button>
        <IconButton
          icon="icon-pin"
          onClick={toggleModelFilterPin}
          size="md"
          variant={modelFilterPined ? "secondary" : "outline-secondary"}
        />
      </div>
      <input id="jsonData" name="jsonData" type="hidden" value="" />
    </div>
  );
};

export default GridFilter;
