import { GridSchema } from "components/GridView/GridView.types";
import Grid from "components/GridView/components/Grid";
import Accordion from "components/blocks/Accordion";
import AccordionItem from "components/blocks/Accordion/AccordionItem";
import AttachmentCard from "components/blocks/AttachmentCard";
import FormBuilderComponent from "components/formBuilder/FormBuilderComponent";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { OrderLocalItemResult } from "services/orders/models/OrdersTypes";
import FormViewComponent from "../../../../../../../components/formBuilder/FormViewComponent";

const ApplicantInfo = ({
  appliedUser,
  files,
  formData,
}: {
  appliedUser?: OrderLocalItemResult["appliedUser"];
  files?: OrderLocalItemResult["files"];
  formData: {
    subject: string;
    value: string;
    schema?: string;
  };
}) => {
  const { t, i18n } = useTranslation("Orders");

  const applicantTableHeaders = useMemo<GridSchema[]>(
    () => [
      {
        accessor: "applicantName",
        field: "applicantName",
        displayName: t("applicantName"),
      },
      {
        accessor: "degree",
        field: "degree",
        displayName: t("academicDegree"),
      },
      {
        accessor: "collage",
        field: "collage",
        displayName: t("collage"),
      },
      {
        accessor: "specialization",
        field: "specialization",
        displayName: t("specialization"),
      },
      {
        accessor: "email",
        field: "email",
        displayName: t("email"),
      },
      {
        accessor: "phoneNumber",
        field: "phoneNumber",
        displayName: t("phone"),
      },
    ],
    [],
  );

  return (
    <Accordion>
      <AccordionItem initiallyOpen title={t("applicantMainInfo")} elementId={1}>
        <div className="d-flex flex-column gap-3 pb-2 border-bottom border-1">
          <Grid
            gridSchema={applicantTableHeaders}
            data={[
              {
                applicantName: i18n.language === "en" ? appliedUser?.englishName : appliedUser?.arabicName,
                degree: appliedUser?.degree,
                college: appliedUser?.college,
                specialization: appliedUser?.specialization,
                email: appliedUser?.email,
                phoneNumber: appliedUser?.phoneNumber,
              },
            ]}
          />

          <div>
            <h6 className="fw-bold">{t("suggestedTitle")}</h6>
            <p className="fs-12px">{formData.subject}</p>
          </div>

          <div>
            <h6 className="fw-bold">{t("fullDescription")}</h6>
            <FormViewComponent
              readOnly
              data={formData.value ? JSON.parse(formData.value) : {}}
              formDefinition={formData.schema ? JSON.parse(formData.schema) : {}}
              onChange={() => {}}
            />
          </div>

          <div className="pb-4">
            <h6 className="fw-bold">{t("attachments")}</h6>
            <div className="d-flex gap-3 mt-2 ">
              {files?.map((attachment) => (
                <AttachmentCard
                  key={attachment.id}
                  attachment={{
                    id: attachment.id,
                    fileName: attachment.name ?? "",
                    size: attachment.size ?? "",
                    extension: attachment.extension ?? "",
                  }}
                />
              ))}
            </div>
          </div>
        </div>
      </AccordionItem>
    </Accordion>
  );
};

export default ApplicantInfo;
