import DatePicker from "components/blocks/DatePicker";
import Modal from "components/blocks/Modal";
import useMutation from "hooks/useMutation";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { ExaminersService } from "services/examiners";
import { ExaminerListResult, ReferralOfExaminersRequest } from "services/examiners/Model/ExaminerTypes";
import { getReferExaminerSchema } from "./referExaminer.schema";
import { useTranslation } from "react-i18next";

const ReferExaminersModal = ({
  isOpen,
  onClose,
  checkedExaminerToReferer,
}: {
  isOpen: boolean;
  onClose: (shouldUpdateExaminerTable?: boolean) => void;
  checkedExaminerToReferer: ExaminerListResult[];
}) => {
  const { t } = useTranslation(["Orders"]);
  const {
    formState: { errors },
    handleSubmit,
    register,
    setValue,
    reset,
  } = useForm<ReferralOfExaminersRequest>({
    defaultValues: {
      deadLine: new Date(),
      examinersIds: checkedExaminerToReferer.map((examiner) => examiner.id),
      notes: "",
    },
    resolver: getReferExaminerSchema(t),
  });

  useEffect(() => {
    if (isOpen) {
      reset({
        deadLine: new Date(),
        examinersIds: checkedExaminerToReferer.map((examiner) => examiner.id),
        notes: "",
      });
    } else {
      reset({
        deadLine: new Date(),
        examinersIds: [],
        notes: "",
      });
    }
  }, [isOpen]);

  const { loading, mutateAsync } = useMutation({
    queryFn: async (request: ReferralOfExaminersRequest) => {
      return await ExaminersService.referralOfExaminers(request);
    },
  });

  const onReferExaminers = async (request: ReferralOfExaminersRequest) => {
    const response = await mutateAsync(request);
    if (!response?.hasError) {
      onClose(true);
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={t("referExaminers")}
      containerClassName="bg-white p-4"
      bodyMargin="p-0"
      size="lg"
      titleClassName="fs-6 text-dark"
    >
      <form noValidate onSubmit={handleSubmit(onReferExaminers)} className="d-flex flex-column gap-2">
        <div className="divider"></div>
        <DatePicker
          label={t("deadLine")}
          labelClassName="fw-bold text-dark fs-12px mb-0"
          placeholder="../../...."
          wrapperClassName="w-50"
          error={errors.deadLine?.message}
          onChange={(date) => {
            if (date) {
              setValue("deadLine", date);
            }
          }}
        />
        <div>
          <label className="fw-bold text-dark fs-12px  mb-1">{t("notes")}</label>
          <textarea
            className="form-control"
            placeholder={t("notes")}
            {...register("notes")}
            rows={5}
            style={{ resize: "none" }}
          />
        </div>

        <div className="d-flex justify-content-center gap-2 mt-2">
          <button
            type="button"
            className="btn border me-2 p-10-32P rounded-3 "
            onClick={() => onClose(false)}
            disabled={loading}
          >
            {t("Common:cancel")}
          </button>
          <button className="btn  btn-primary border me-2 p-10-32P rounded-3" type="submit">
            {!loading ? t("Common:send") : <div className="custom-loader"></div>}
          </button>
        </div>
      </form>
    </Modal>
  );
};

export default ReferExaminersModal;
