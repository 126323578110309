import { useEffect, useState } from "react";
import { DropdownItemType } from "./Dropdown";
import { useTranslation } from "react-i18next";

export type MultiDropdownProps = {
  label?: string;
  data: DropdownItemType[];
  wrapperClassName?: string;
  value?: string[] | number[];
  onChange?: (value: string[]) => void;
  disabled?: boolean;
  formProps?: any;
  changeEffect?: (value?: any) => void;
  error?: string;
  labelClassName?: string;
};

const MultiSelectDropdown: React.FC<MultiDropdownProps> = (props) => {
  const { label, data, error, wrapperClassName, value, onChange, disabled, formProps, changeEffect, labelClassName } = props;

  const { t } = useTranslation();
  const [selectedValues, setSelectedValues] = useState<string[]>([]);

  const onLocalChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedOption = e.target.value;
    if (selectedOption === "0") return;
    const selectedOptions = selectedValues.includes(selectedOption)
      ? selectedValues.filter((v) => v !== selectedOption)
      : [...selectedValues, selectedOption];
    setSelectedValues(selectedOptions);
    onChange?.(selectedOptions);
    changeEffect?.(selectedOptions);
  };

  useEffect(() => {
    setSelectedValues(value?.map((v) => `${v}`) ?? []);
  }, [value]);

  return (
    <div className={`${wrapperClassName}`} style={{ minWidth: 150 }}>
      {label && <label className={`mb-1  ${labelClassName}`}>{label}</label>}
      <select
        className={`form-select form-control ${error ? "is-invalid" : ""}`}
        aria-label={label}
        disabled={disabled}
        {...formProps}
        value={0}
        onChange={onLocalChange}
      >
        <option value="0" className="text-muted bg-white" disabled>
          {selectedValues.length === 1
            ? data.find((x) => `${x.id}` === selectedValues[0])?.value
            : `${selectedValues.length} ${t("Common:selected")}`}
        </option>
        {data.map((item) => (
          <option
            key={item.id}
            value={item.id}
            className={selectedValues.includes(`${item.id}`) ? "bg-primary text-white" : ""}
          >
            {selectedValues.includes(`${item.id}`) ? `✓ ${item.value}` : item.value}
          </option>
        ))}
      </select>
      {error && <div className="invalid-feedback">{error}</div>}
    </div>
  );
};

export default MultiSelectDropdown;
