import { useShallow } from "zustand/react/shallow";
import useGridStore from "../grid.store";
import { GridProps } from "../GridView.types";
import { SortDirection } from "services/shared-models/sharedEnums";
import { useTranslation } from "react-i18next";
import { DragDropContext, Droppable, Draggable, type DropResult } from "react-beautiful-dnd";

const Grid: React.FC<GridProps> = (props) => {
  const { t } = useTranslation("Common");
  const { data, gridSchema, draggable, onRowDragEnd } = props;
  const { sortState, toggleFieldSortState } = useGridStore(
    useShallow((state) => ({
      sortState: state.sortState,
      toggleFieldSortState: state.toggleFieldSortState,
    })),
  );

  const handleToggleSortClick = (field: string) => {
    const dir = sortState?.[field];
    if (!dir) return toggleFieldSortState(field, SortDirection.Asc);

    const newDir = dir === SortDirection.Asc ? SortDirection.Desc : null;
    toggleFieldSortState(field, newDir);
  };

  const shownColumns = gridSchema.filter((col) => !col.isHidden);

  const onDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return;
    }

    const fromIndex = result.source.index;
    const toIndex = result.destination.index;

    onRowDragEnd?.({ from: fromIndex, to: toIndex });
  };

  return (
    <div className="table-responsive">
      <DragDropContext onDragEnd={onDragEnd}>
        <table
          style={{ width: "100%", borderCollapse: "collapse" }}
          className={`table table-rounded ${props.gridFooter ? "has-footer" : ""}`}
        >
          <thead>
            <tr>
              {draggable && <th style={{ width: "40px" }}></th>}
              {shownColumns.map((header) =>
                header.sortable ? (
                  <th
                    key={header.field}
                    className={`user-select-none sortable ${SortDirection[sortState?.[header.field] ?? 0]} ${
                      header?.columnClassName ?? ""
                    }`}
                    onClick={() => handleToggleSortClick(header.field)}
                  >
                    {header.renderHeader ? header.renderHeader() : header.displayName}
                  </th>
                ) : (
                  <th className="user-select-none" key={header.field}>
                    {header.renderHeader ? header.renderHeader() : header.displayName}
                  </th>
                ),
              )}
            </tr>
          </thead>
          <Droppable droppableId="table-body">
            {(provided) => (
              <tbody {...provided.droppableProps} ref={provided.innerRef} style={{ display: "table-row-group" }}>
                {data.length === 0 && (
                  <tr>
                    <td colSpan={draggable ? shownColumns.length + 1 : shownColumns.length} className="text-center">
                      {t("noData")}
                    </td>
                  </tr>
                )}
                {data.map((row, idx) => (
                  <Draggable key={idx} draggableId={`row-${idx}`} index={idx} isDragDisabled={!draggable}>
                    {(provided, snapshot) => (
                      <tr
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        style={{
                          ...provided.draggableProps.style,
                          display: "table-row",
                          background: snapshot.isDragging ? "#fcfcfc" : "inherit",
                        }}
                      >
                        {draggable && (
                          <td
                            {...provided.dragHandleProps}
                            style={{
                              cursor: "move",
                              width: "40px",
                              padding: "8px",
                              borderBottom: "1px solid #e0e0e0",
                              verticalAlign: "middle",
                            }}
                          >
                            <span className="icon-drag" role="button" style={{ width: 24, height: 24 }} />
                          </td>
                        )}
                        {shownColumns.map((header) => (
                          <td
                            key={header.field}
                            style={{
                              verticalAlign: "middle",
                              padding: "8px",
                              borderBottom: "1px solid #e0e0e0",
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                            }}
                          >
                            {header.render ? header.render(row, idx) : row[header.accessor ?? header.field]}
                          </td>
                        ))}
                      </tr>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </tbody>
            )}
          </Droppable>
          {props.gridFooter && (
            <tfoot>
              {props.gridFooter.map((footerRow, rowIndex) => (
                <tr key={rowIndex}>
                  {footerRow.map((footerCell, cellIndex) => (
                    <td key={cellIndex} colSpan={footerCell.colSpan ?? 1} className={footerCell.cellClassName}>
                      {footerCell.content}
                    </td>
                  ))}
                </tr>
              ))}
            </tfoot>
          )}
        </table>
      </DragDropContext>
    </div>
  );
};

export default Grid;
