import OrderGridView from "../../../orders/OrderGridView";
import React from "react";

type OrdersTabProps = {
  programId: number;
};

const OrdersTab: React.FC<OrdersTabProps> = ({ programId }) => {
  console.log(programId);
  return (
    <div style={{ margin: "-1.5rem" }}>
      <OrderGridView programId={programId} />
    </div>
  );
};

export default OrdersTab;
