import Accordion from "components/blocks/Accordion";
import AccordionItem from "components/blocks/Accordion/AccordionItem";
import AttachmentCard from "components/blocks/AttachmentCard";
import { useTranslation } from "react-i18next";

const AchievementFile = () => {
  const { t } = useTranslation("Orders");

  const achievementProps = {
    attachments: [
      {
        id: 1,
        fileName: "اسم الملف.pdf",
        size: "1MB",
        extension: "pdf",
      },
      {
        id: 2,
        fileName: "اسم الملف.pdf",
        size: "2MB",
        extension: "doc",
      },
      {
        id: 3,
        fileName: "اسم الملف.pdf",
        size: "1MB",
        extension: "pdf",
      },
      {
        id: 4,
        fileName: "اسم الملف.pdf",
        size: "1MB",
        extension: "pdf",
      },
      {
        id: 5,
        fileName: "اسم الملف.pdf",
        size: "1MB",
        extension: "pdf",
      },
    ],
    journalSearchLink: "رابط البحث عن المجلة",
    journalName: "اسم المجلة",
    journalClassification: "تصنيف المجلة",
    journalOrder: "ترتيب المجلة",
  };
  return (
    <Accordion>
      <AccordionItem initiallyOpen title={t("achievementFile")} elementId={6}>
        <div className="d-flex flex-column gap-4">
          <div className="d-flex flex-wrap gap-3">
            {achievementProps.attachments.map((attachment) => (
              <AttachmentCard
                key={attachment.id}
                attachment={{
                  id: attachment.id,
                  fileName: attachment.fileName,
                  size: attachment.size,
                  extension: attachment.extension,
                }}
              />
            ))}
          </div>
          <div className="row">
            <div className="col-lg-3 col-md-6">
              <label>{t("journalSearchLink")}</label>
              <input type="text" className="form-control" disabled value={achievementProps.journalSearchLink} />
            </div>
            <div className="col-lg-3 col-md-6">
              <label>{t("journalName")}</label>
              <input type="text" className="form-control" disabled value={achievementProps.journalName} />
            </div>
            <div className="col-lg-3 col-md-6">
              <label>{t("journalClassification")}</label>
              <input type="text" className="form-control" disabled value={achievementProps.journalClassification} />
            </div>
            <div className="col-lg-3 col-md-6">
              <label>{t("journalOrder")}</label>
              <input type="text" className="form-control" disabled value={achievementProps.journalOrder} />
            </div>
          </div>
        </div>
      </AccordionItem>
    </Accordion>
  );
};

export default AchievementFile;
