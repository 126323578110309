import PageWithTabs from "components/PageWithTabs";
import IconButton from "components/blocks/IconButton";
import { useTranslation } from "react-i18next";
import PersonalInfo from "./Tabs/PersonalInfo";
import ProfessionalInfo from "./Tabs/ProfessionalInfo";
import OrdersTab from "./Tabs/OrdersTab";
import { useQuery } from "hooks/useQuery";
import { AccountService } from "services/accountService";
import { useMemo } from "react";
import { useCookieContext } from "contexts";

type TapsIds = "ProfessionalInfo" | "OrdersTab" | "PersonalInfo";

const tabsIds: {
  [key in TapsIds]: TapsIds;
} = {
  ProfessionalInfo: "ProfessionalInfo",
  OrdersTab: "OrdersTab",
  PersonalInfo: "PersonalInfo",
};

const UserProfile = () => {
  const { t, i18n } = useTranslation("UserProfile");
  const { isAr } = useCookieContext();

  //! get user from query
  const params = new URLSearchParams(window.location.search);
  const userId = params.get("researcherId") ?? params.get("examinerId") ?? params.get("userId");
  const pageParams = {
    id: userId ?? "",
    isResearcherRoute: !!params.get("researcherId"),
  };

  const { data: user, loading } = useQuery({
    queryFn: async () => {
      return await AccountService.getUserByIdAsync(pageParams.id);
    },
    options: {
      enabled: !!pageParams.id,
    },
    triggers: [isAr],
  });

  const userRoles = useMemo(() => {
    return user?.roles.reduce((acc, role, i) => {
      return (
        acc +
          role.roleDetails.find(
            (roleDetail) => roleDetail.locale.toLocaleLowerCase() === i18n.language.toLocaleLowerCase(),
          )?.displayName +
          (i === user.roles.length - 1 ? "" : ", ") ?? ""
      );
    }, "");
  }, [user, i18n.language]);

  return (
    <PageWithTabs
      title={i18n.language === "ar" ? user?.arabicName : user?.englishName}
      subTitle={`${t("userNumber")}: ${user?.id ?? ""}`}
      defaultTabId={tabsIds.PersonalInfo}
      loading={loading}
      pageHeaders={[
        {
          title: t("email"),
          value: user?.email,
        },
        {
          title: t("phoneNumber"),
          value: user?.phoneNumber,
        },
        {
          title: t("username"),
          value: user?.username,
        },
        {
          title: t("role"),
          value: userRoles,
        },
        {
          title: t("status"),
          value: user ? (user?.isActive ? t("active") : t("inactive")) : "",
        },
      ]}
      actions={(selectedTabId) => {
        return (
          <>
            {/*  <IconButton
              icon="icon-share"
              size="md"
              className="btn action-btn btn-sm rounded p-2"
              variant="light"
              innerIconColor="secondary"
            />
            <IconButton
              icon="icon-edit"
              size="md"
              className="btn action-btn btn-sm rounded p-2"
              variant="light"
              innerIconColor="secondary"
            />
            <IconButton
              icon="icon-delete"
              size="md"
              className="btn action-btn btn-sm rounded p-2"
              variant="light"
              innerIconColor="danger"
            /> */}
          </>
        );
      }}
      tabs={[
        {
          id: tabsIds.PersonalInfo,
          title: t("personalInfo"),
          content: <PersonalInfo user={user} />,
        },
        {
          id: tabsIds.ProfessionalInfo,
          title: t("professionalInfo"),
          content: <ProfessionalInfo user={user} />,
        },
        !pageParams.isResearcherRoute || !user?.id
          ? null
          : {
              id: tabsIds.OrdersTab,
              title: t("orders"),
              content: <OrdersTab userId={user.id} />,
            },
      ]}
    />
  );
};

export default UserProfile;
