import { DataGridRequest, PagedResponseType } from "services/shared-models/sharedTypes";
import { ResearcherPaymentLocalResult } from "./models/ResearcherPaymentsType";

export const ResearcherPaymentsService = {
  getAllResearcherPaymentsAsync: async (data: DataGridRequest) => {
    /* return await HttpClient.post<PagedResponseType<ResearcherPaymentLocalResult>>(
      "/api/ResearcherPayments/GetAllResearcherPayments",
      data,
    ); */
    return await new Promise<PagedResponseType<ResearcherPaymentLocalResult>>((resolve) => {
      resolve({
        code: 200,
        description: "Success",
        hasError: false,
        data: {
          result: [
            {
              id: 1,
              researcherName: "اسم الباحث",
              orderNumber: "رقم الطلب",
              orderId: 6,
              exchangeDate: new Date(),
              amount: 100,
              status: "paid",
              receiptNumber: "1",
            },
            {
              id: 2,
              researcherName: "اسم الباحث",
              orderNumber: "رقم الطلب",
              exchangeDate: new Date(),
              orderId: 7,
              amount: 200,
              status: "pending",
            },
            {
              id: 3,
              researcherName: "اسم الباحث",
              orderNumber: "رقم الطلب",
              exchangeDate: new Date(),
              orderId: 8,
              amount: 300,
              status: "paid",
              receiptNumber: "3",
            },
            {
              id: 4,
              researcherName: "اسم الباحث",
              orderNumber: "رقم الطلب",
              orderId: 9,
              exchangeDate: new Date(),
              amount: 400,
              status: "unpaid",
            },
            {
              id: 5,
              researcherName: "اسم الباحث",
              orderNumber: "رقم الطلب",
              orderId: 10,
              exchangeDate: new Date(),
              amount: 500,
              status: "paid",
              receiptNumber: "5",
            },
          ],
          totalCount: 1,
        },
      });
    });
  },
};
