import i18next from "i18next";
import Cookies from "js-cookie";
import { TranslationModel } from "./models/translationModel";
import LoginArView from "../../views/account/login/i18n/ar.json";
import LoginEnView from "../../views/account/login/i18n/en.json";
import RegisterEnView from "../../views/account/register/i18n/en.json";
import RegisterArView from "../../views/account/register/i18n/ar.json";
import SharedEnView from "../../views/home/shared/i18n/en.json";
import SharedArView from "../../views/home/shared/i18n/ar.json";
import SetPasswordEnView from "../../views/account/setPassword/i18n/en.json";
import SetPasswordArViewAr from "../../views/account/setPassword/i18n/ar.json";
import CommonAr from "./common/ar.json";
import CommonEn from "./common/en.json";
import ProgramsAr from "../../views/home/programs/i18n/ar.json";
import ProgramsEn from "../../views/home/programs/i18n/en.json";
import OrdersAr from "../../views/home/orders/i18n/ar.json";
import OrdersEn from "../../views/home/orders/i18n/en.json";
import TasksAr from "../../views/home/tasks/i18n/ar.json";
import TasksEn from "../../views/home/tasks/i18n/en.json";
import WelcomePageAr from "../../views/welcomePage/i18n/ar.json";
import WelcomePageEn from "../../views/welcomePage/i18n/en.json";
import ExaminerTaskAr from "../../views/home/tasks/ExaminerTaskView/i18n/ar.json";
import ExaminerTaskEn from "../../views/home/tasks/ExaminerTaskView/i18n/en.json";
import ResearchersAr from "../../views/home/researchers/i18n/ar.json";
import ResearchersEn from "../../views/home/researchers/i18n/en.json";
import UserProfileAr from "../../views/home/userProfile/i18n/ar.json";
import UserProfileEn from "../../views/home/userProfile/i18n/en.json";
import ResearcherPaymentsAr from "../../views/home/researcherPayments/i18n/ar.json";
import ResearcherPaymentsEn from "../../views/home/researcherPayments/i18n/en.json";
import ExaminerRewardsAr from "../../views/home/examinerRewards/i18n/ar.json";
import ExaminerRewardsEn from "../../views/home/examinerRewards/i18n/en.json";
import OrdersMyWorkAr from "../../views/home/researcherOrders/MySignedWork/i18n/ar.json";
import OrdersMyWorkEn from "../../views/home/researcherOrders/MySignedWork/i18n/en.json";
import OrdersUnsignedWorkAr from "../../views/home/researcherOrders/MyUnsignedWork/i18n/ar.json";
import OrdersUnsignedWorkEn from "../../views/home/researcherOrders/MyUnsignedWork/i18n/en.json";
import EvaluationFormsAr from "../../views/home/evaluationForms/i18n/ar.json";
import EvaluationFormsEn from "../../views/home/evaluationForms/i18n/en.json";
import OrderActionsAr from "../../views/home/orders/OrderActionsGrid/i18n/ar.json";
import OrderActionsEn from "../../views/home/orders/OrderActionsGrid/i18n/en.json";
import ExaminersAr from "../../views/home/examiners/i18n/ar.json";
import ExaminersEn from "../../views/home/examiners/i18n/en.json";

export function localizationInit() {
  i18next.init({
    interpolation: { escapeValue: false }, // React already does escaping
    fallbackLng: ["ar", "en"],
    lng: "ar",
    resources: {
      ar: {
        LoginView: LoginArView,
        RegisterView: RegisterArView,
        Shared: SharedArView,
        SetPasswordView: SetPasswordArViewAr,
        Common: CommonAr,
        Programs: ProgramsAr,
        Orders: OrdersAr,
        Tasks: TasksAr,
        WelcomePage: WelcomePageAr,
        ExaminerTask: ExaminerTaskAr,
        Researchers: ResearchersAr,
        UserProfile: UserProfileAr,
        OrdersMyWork: OrdersMyWorkAr,
        OrdersUnsignedWork: OrdersUnsignedWorkAr,
        ResearcherPayments: ResearcherPaymentsAr,
        ExaminerRewards: ExaminerRewardsAr,
        EvaluationForms: EvaluationFormsAr,
        OrderActions: OrderActionsAr,
        Examiners: ExaminersAr,
      },
      en: {
        LoginView: LoginEnView,
        Shared: SharedEnView,
        RegisterView: RegisterEnView,
        SetPasswordView: SetPasswordEnView,
        Common: CommonEn,
        Programs: ProgramsEn,
        Orders: OrdersEn,
        Tasks: TasksEn,
        WelcomePage: WelcomePageEn,
        ExaminerTask: ExaminerTaskEn,
        Researchers: ResearchersEn,
        UserProfile: UserProfileEn,
        OrdersMyWork: OrdersMyWorkEn,
        OrdersUnsignedWork: OrdersUnsignedWorkEn,
        ResearcherPayments: ResearcherPaymentsEn,
        ExaminerRewards: ExaminerRewardsEn,
        EvaluationForms: EvaluationFormsEn,
        OrderActions: OrderActionsEn,
        Examiners: ExaminersEn,
      },
    },
  });
  const localizationStr = Cookies.get("localization");
  if (localizationStr) {
    const localization: TranslationModel = JSON.parse(localizationStr);
    i18next.changeLanguage(localization.CurrentLanguage);
    if (localization.isRTL) {
      document.body.classList.add("rtl");
    } else {
      document.body.classList.remove("rtl");
    }
    document.documentElement.lang = localization.CurrentLanguage;
  } else {
    const localization: TranslationModel = {
      CurrentLanguage: "ar",
      isRTL: true,
    };
    Cookies.set("localization", JSON.stringify(localization));

    i18next.changeLanguage(localization.CurrentLanguage);
    if (localization.isRTL) {
      document.body.classList.add("rtl");
    } else {
      document.body.classList.remove("rtl");
    }
  }
}

export const languageChange = (currentLanguage: string) => {
  const isRtl = currentLanguage === "ar";
  // const direction = currentLanguage === "ar" ? "rtl" : "";
  const locale: TranslationModel = {
    CurrentLanguage: currentLanguage,
    isRTL: isRtl,
  };
  localStorage.setItem("localization", JSON.stringify(locale));
  if (isRtl) document.body.classList.add("rtl");
  else document.body.classList.remove("rtl");
  document.documentElement.lang = currentLanguage;
  i18next.changeLanguage(currentLanguage);
};

export const toArabicNumbers = (value: string) => {
  if (!value) {
    return "";
  }
  return value.replace(/\d/g, (d: any) => "٠١٢٣٤٥٦٧٨٩"[d]).replace(/\./g, "٫");
};
