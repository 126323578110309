import { RouteItemConfig } from "../models";
import DashboardView from "../../views/home/dashboard/dashboardView";
import ProgramsGridView from "views/home/programs/ProgramsGridView";
import ProgramFormView from "views/home/programs/Forms/ProgramFormView";
import OrderGridView from "views/home/orders/OrderGridView";
import OrderView from "views/home/orders/OrderView";
import SamplesView from "views/home/programs/SamplesView";
import TasksGridView from "views/home/tasks/TasksGridView";
import ViewProgram from "views/home/programs/ViewProgram";
import OrderForm from "views/home/orders/OrderForm";
import ExaminerTaskView from "views/home/tasks/ExaminerTaskView";
import ResearchersGridView from "views/home/researchers/ResearchersGridView";
import UserProfile from "views/home/userProfile";
import ResearcherPaymentsGridView from "views/home/researcherPayments/ResearcherPaymentsGridView";
import { Outlet } from "react-router-dom";
import ExaminerRewardsGridView from "views/home/examinerRewards/ExaminerRewardsGridView";
import MyUnsignedWorkView from "views/home/researcherOrders/MyUnsignedWork/MyUnsignedWorkView";
import MyUnsignedWorkGridView from "views/home/researcherOrders/MyUnsignedWork/MyUnsignedWorkGridView";
import MySignedWorkGridView from "views/home/researcherOrders/MySignedWork/MySignedWorkGridView";
import MySignedWorkView from "views/home/researcherOrders/MySignedWork/MySignedWorkView";
import EvaluationFormsGridView from "views/home/evaluationForms/EvaluationFormsGridView.tsx";
import EvaluationFormAddUpdateView from "views/home/evaluationForms/FormView";
import PublicProgramsGridView from "views/home/publicPrograms/publicProgramsGridView";
import ExaminersGridView from "views/home/examiners/ExaminersGridView";

export const HomeRoutes: RouteItemConfig[] = [
  {
    path: "/home/dashboard",
    name: "dashboard",
    component: DashboardView,
    icon: "icon-dashboard",
    layout: "/home",
    default: true,
    isExact: true,
    authorize: true,
    roles: [],
    showInMenu: true,
    isRoute: true,
    id: 1,
  },
  {
    path: "/home/programs",
    name: "allPrograms",
    component: ProgramsGridView,
    icon: "icon-programs",
    layout: "/home",
    default: true,
    isExact: true,
    authorize: true,
    roles: [],
    showInMenu: true,
    isRoute: true,
    id: 1,
    groupName: "essentials",
  },
  {
    path: "/home/programs/form",
    name: "",
    component: ProgramFormView,
    icon: "",
    layout: "/home",
    default: true,
    isExact: true,
    authorize: true,
    roles: [],
    showInMenu: false,
    isRoute: true,
  },
  {
    path: "/home/programs/:programId",
    name: "",
    component: ViewProgram,
    icon: "",
    layout: "/home",
    default: true,
    isExact: true,
    authorize: true,
    roles: [],
    showInMenu: false,
    isRoute: true,
  },
  {
    path: "/home/programs/public",
    name: "publicPrograms",
    component: PublicProgramsGridView,
    icon: "icon-programs",
    layout: "/home",
    default: true,
    isExact: true,
    authorize: true,
    roles: [],
    showInMenu: true,
    isRoute: true,
    groupName: "essentials",
  },
  {
    path: "/home/samples",
    name: "",
    component: SamplesView,
    icon: "",
    layout: "/home",
    default: true,
    isExact: true,
    authorize: true,
    roles: [],
    showInMenu: false,
    isRoute: true,
  },
  {
    path: "/home/orders",
    name: "allOrders",
    component: OrderGridView,
    icon: "icon-orders",
    layout: "/home",
    default: true,
    isExact: true,
    authorize: true,
    roles: [],
    showInMenu: true,
    isRoute: true,
    id: 1,
    groupName: "essentials",
  },
  {
    path: "/home/orders/view/:id",
    name: "allOrders",
    component: () => <OrderView userType="Admin" />,
    icon: "icon-orders",
    layout: "/home",
    default: true,
    isExact: true,
    authorize: true,
    roles: [],
    showInMenu: false,
    isRoute: true,
    id: 1,
  },
  {
    path: "/home/orders/centralManagement/:serialGID",
    name: "allOrders",
    component: () => <OrderView userType="CentralManagement" />,
    icon: "icon-orders",
    layout: "/home",
    default: true,
    isExact: true,
    authorize: true,
    roles: [],
    showInMenu: false,
    isRoute: true,
    id: 1,
  },
  {
    path: "/home/orders/edit/:orderId",
    name: "editOrder",
    component: () => <OrderForm orderFormType="EditOrder" />,
    icon: "icon-orders",
    layout: "/home",
    default: false,
    isExact: true,
    authorize: true,
    roles: [],
    showInMenu: false,
    isRoute: true,
    id: 1,
  },
  {
    path: "/home/orders/editOrderTask/:serialGID",
    name: "editOrder",
    component: () => <OrderForm orderFormType="ReturnedOrder" />,
    icon: "icon-orders",
    layout: "/home",
    default: false,
    isExact: true,
    authorize: true,
    roles: [],
    showInMenu: false,
    isRoute: true,
    id: 1,
  },
  {
    path: "/home/orders/add",
    name: "allOrders",
    component: () => <OrderForm orderFormType="AddOrder" />,
    layout: "/home",
    default: true,
    isExact: true,
    authorize: true,
    roles: [],
    showInMenu: false,
    isRoute: true,
    id: 1,
  },
  {
    path: "/home/tasks",
    name: "tasks",
    component: TasksGridView,
    icon: "icon-tasks",
    layout: "/home",
    default: true,
    isExact: true,
    authorize: true,
    roles: [],
    showInMenu: true,
    isRoute: true,
    id: 1,
    groupName: "essentials",
  },
  {
    path: "/home/tasks/examiner/:serialGID",
    name: "examiner-task",
    component: ExaminerTaskView,
    icon: "",
    layout: "/home",
    default: true,
    isExact: true,
    authorize: true,
    roles: [],
    showInMenu: false,
    isRoute: true,
    id: 1,
  },
  {
    path: "/home/researchersManagement",
    name: "researchersManagement",
    component: Outlet,
    icon: "icon-researchers",
    layout: "/home",
    default: true,
    isExact: true,
    authorize: true,
    roles: [],
    showInMenu: true,
    isRoute: true,
    id: 1,
    groupName: "essentials",
    subMenu: [
      {
        path: "/home/researchersManagement/researchers",
        name: "researchersInfo",
        component: ResearchersGridView,
        icon: "icon-researchers",
        layout: "/home",
        default: true,
        isExact: true,
        authorize: true,
        roles: [],
        showInMenu: true,
        isRoute: true,
        id: 1,
        groupName: "essentials",
      },
      {
        path: "/home/researchersManagement/researchers/view",
        name: "researcherInfo",
        component: UserProfile,
        icon: "icon-researchers",
        layout: "/home",
        default: false,
        isExact: true,
        authorize: true,
        roles: [],
        showInMenu: false,
        isRoute: true,
        id: 1,
      },
      {
        path: "/home/researchersManagement/researcherPayments",
        name: "researcherPayments",
        component: ResearcherPaymentsGridView,
        icon: "icon-dashboard",
        layout: "/home",
        default: false,
        isExact: true,
        authorize: true,
        roles: [],
        showInMenu: true,
        isRoute: true,
      },
    ],
  },
  {
    path: "/home/examinersManagement",
    name: "examinersManagement",
    component: Outlet,
    icon: "icon-researchers",
    layout: "/home",
    default: true,
    isExact: true,
    authorize: true,
    roles: [],
    showInMenu: true,
    isRoute: true,
    id: 1,
    groupName: "essentials",
    subMenu: [
      {
        path: "/home/examinersManagement/examiners",
        name: "examinersInfo",
        component: ExaminersGridView,
        icon: "icon-researcher",
        layout: "/home",
        default: false,
        isExact: true,
        authorize: true,
        roles: [],
        showInMenu: true,
        isRoute: true,
      },
      {
        path: "/home/examinersManagement/examiners/view",
        name: "researcherInfo",
        component: UserProfile,
        icon: "icon-researchers",
        layout: "/home",
        default: false,
        isExact: true,
        authorize: true,
        roles: [],
        showInMenu: false,
        isRoute: true,
        id: 1,
      },
      {
        path: "/home/examinersManagement/examinerRewards",
        name: "examinerRewards",
        component: ExaminerRewardsGridView,
        icon: "icon-dashboard",
        layout: "/home",
        default: false,
        isExact: true,
        authorize: true,
        roles: [],
        showInMenu: true,
        isRoute: true,
      },
    ],
  },
  {
    path: "/home/userProfile",
    name: "researcherInfo",
    component: UserProfile,
    icon: "icon-researchers",
    layout: "/home",
    default: false,
    isExact: true,
    authorize: true,
    roles: [],
    showInMenu: false,
    isRoute: true,
    id: 1,
  },
  {
    path: "/home/orders/researcherOrders",
    name: "orders",
    component: Outlet,
    icon: "icon-orders",
    layout: "/home",
    default: true,
    isExact: true,
    authorize: true,
    roles: [],
    showInMenu: true,
    isRoute: true,
    id: 1,
    groupName: "essentials",
    subMenu: [
      {
        path: "/home/orders/researcherOrders/myWork",
        name: "mySignedWork",
        component: MySignedWorkGridView,
        icon: "",
        layout: "/home",
        default: true,
        isExact: true,
        authorize: true,
        roles: [],
        showInMenu: true,
        isRoute: true,
      },
      {
        path: "/home/orders/researcherOrders/allUnsignedOrders",
        name: "allUnsignedOrders",
        component: MyUnsignedWorkGridView,
        icon: "",
        layout: "/home",
        default: true,
        isExact: true,
        authorize: true,
        roles: [],
        showInMenu: true,
        isRoute: true,
      },
      {
        path: "/home/orders/researcherOrders/allUnsignedOrders/:id",
        name: "",
        component: MyUnsignedWorkView,
        icon: "",
        layout: "/home",
        default: true,
        isExact: true,
        authorize: true,
        roles: [],
        showInMenu: false,
        isRoute: true,
      },
      {
        path: "/home/orders/researcherOrders/myWork/:id",
        name: "",
        component: MySignedWorkView,
        icon: "",
        layout: "/home",
        default: true,
        isExact: true,
        authorize: true,
        roles: [],
        showInMenu: false,
        isRoute: true,
      },
    ],
  },
  {
    path: "/home/evaluationForms",
    name: "evaluationForms",
    component: EvaluationFormsGridView,
    icon: "icon-eval-form",
    layout: "/home",
    default: true,
    isExact: true,
    authorize: true,
    roles: [],
    showInMenu: true,
    isRoute: true,
    id: 1,
    groupName: "essentials",
  },
  {
    path: "/home/evaluationForms/form",
    name: "",
    component: EvaluationFormAddUpdateView,
    icon: "",
    layout: "/home",
    default: true,
    isExact: true,
    authorize: true,
    roles: [],
    showInMenu: false,
    isRoute: true,
    id: 1,
    groupName: "essentials",
  },
];
