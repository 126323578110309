import { DataGridRequest, PagedResponseType } from "services/shared-models/sharedTypes";
import { ExaminerRewardsLocalResult } from "./Models/ExaminerRewardsTypes";

export const ExaminerRewardsService = {
  getAllExaminerRewardsAsync: async (data: DataGridRequest) => {
    // return await HttpClient.post<PagedResponseType<ExaminerRewardsLocalResult>>("/api/ExaminerRewardss/GetAllExaminerRewardss", data);
    return new Promise<PagedResponseType<ExaminerRewardsLocalResult>>((resolve) => {
      resolve({
        code: 200,
        description: "Success",
        hasError: false,
        data: {
          result: [
            {
              id: "130c3afa-34a0-4d03-0135-08dcd353ecc2",
              examinerName: "اسم المحكم",
              orderId: 6,
              orderNumber: "رقم الطلب",
              applicantName: "اسم المتقدم",
              researchesCenter: "مركز الباحثين",
              rewardConfirmationDate: new Date(),
              amount: 100,
              status: "unpaid",
              statusId: 1,
            },
            {
              id: "130c3afa-34a0-4d03-0135-08dcd353ecc2",
              examinerName: "اسم المحكم",
              orderId: 7,
              orderNumber: "رقم الطلب",
              applicantName: "اسم المتقدم",
              researchesCenter: "مركز الباحثين",
              rewardConfirmationDate: new Date(),
              amount: 200,
              status: "paid",
              statusId: 2,
            },
            {
              id: "130c3afa-34a0-4d03-0135-08dcd353ecc2",
              examinerName: "اسم المحكم",
              orderId: 8,
              orderNumber: "رقم الطلب",
              applicantName: "اسم المتقدم",
              researchesCenter: "مركز الباحثين",
              rewardConfirmationDate: new Date(),
              amount: 300,
              status: "pending",
              statusId: 3,
            },
            {
              id: "130c3afa-34a0-4d03-0135-08dcd353ecc2",
              examinerName: "اسم المحكم",
              orderId: 9,
              orderNumber: "رقم الطلب",
              applicantName: "اسم المتقدم",
              researchesCenter: "مركز الباحثين",
              rewardConfirmationDate: new Date(),
              amount: 400,
              status: "unpaid",
              statusId: 4,
            },
          ],
          totalCount: 4,
        },
      });
    });
  },
};
