import { HttpClient } from "helpers";
import { DataGridRequest } from "services/shared-models/sharedTypes";
import { GetAllTasksResponse, OpenFundingTaskResult, ReAssignRequest, ReAssignResponse } from "./models/tasksTypes";
import { AxiosResponse } from "axios";

export const TasksService = {
  getMyTasks: async (data: DataGridRequest) => {
    return await HttpClient.post<GetAllTasksResponse>("/api/Tasks/GetMyTasks", data);
  },
  exportTasksToExcel: async (data: DataGridRequest) => {
    return await HttpClient.post<AxiosResponse<Blob>>("/api/Tasks/ExportTasksToExcel", data, {
      responseType: "blob",
    });
  },
  openFundingOrderTask: async (serialId: string) => {
    return await HttpClient.get<OpenFundingTaskResult>(`/api/Tasks/OpenFundingOrderTask/${serialId}`);
  },
  reAssignTask: async (data: ReAssignRequest) => {
    return await HttpClient.post<ReAssignResponse>(`/api/Tasks/ReAssignTask`, data);
  },
};
