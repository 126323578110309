import { HttpClient } from "helpers";
import { BaseResponseType, DataGridRequest, PagedResponseType } from "services/shared-models/sharedTypes";
import {
  AdditionalAttachmentsResult,
  CentreManagementLocalResult,
  CreateAdditionalAttachmentRequest,
  CreateOrderRequest,
  CreateProgramMinuteRequest,
  CreateProgramSpeechesRequest,
  GetAllOrdersResponse,
  GetAllMyProgramsOrderResult,
  OrderLocalItemResult,
  OrderResult,
  PdfTemplateLocalResult,
  ProgramMinutesResult,
  ProgramSpeechesResult,
  UserProgramOrdersResult,
  GetAllMyProgramsOrderSignedStatusResult,
  OrderActionResult,
  UpdateProgramEvaluationOrderRequest,
  ReturnOrderRequest,
  SendToFinanceUnitRequest,
} from "./models/OrdersTypes";
import { AxiosResponse } from "axios";

export const OrdersService = {
  getAllOrdersAsync: async (data: DataGridRequest) => {
    return await HttpClient.post<GetAllOrdersResponse>("/api/Orders/GetAllOrders", data);
  },
  getOneById: async (id: number) => {
    return await HttpClient.get<BaseResponseType<OrderLocalItemResult>>(`/api/Orders/GetOrderById/${id}`);
  },
  createOrder: async (data: CreateOrderRequest) => {
    return await HttpClient.post<BaseResponseType<OrderResult>>("/api/Orders/CreateOrder", data);
  },
  exportToExcel: async (data: DataGridRequest) => {
    return await HttpClient.post<AxiosResponse<Blob>>("/api/Orders/ExportOrdersToExcel", data, {
      responseType: "blob",
    });
  },
  getManagementCentersDropdown: async (data: DataGridRequest) => {
    return await HttpClient.post<BaseResponseType<CentreManagementLocalResult>>(
      "/api/Orders/GetManagementCentersDropdown",
      data,
    );
  },
  updateOrder: async (id: number, data: CreateOrderRequest) => {
    return await HttpClient.put<BaseResponseType<OrderResult>>(`/api/Orders/UpdateOrder/${id}`, data);
  },
  returnOrder: async (programOrderId: number, data: ReturnOrderRequest) => {
    return await HttpClient.put<BaseResponseType<OrderResult>>(`/api/Orders/ReturnOrder/${programOrderId}`, data);
  },

  sendToFinanceUnit: async (programOrderId: number, data: SendToFinanceUnitRequest) => {
    return await HttpClient.put<BaseResponseType<OrderResult>>(`/api/Orders/SendToFinanceUnit/${programOrderId}`, data);
  },

  // program minutes
  createProgramMinute: async (data: CreateProgramMinuteRequest) => {
    return await HttpClient.post<BaseResponseType<ProgramMinutesResult>>("/api/Orders/CreateProgramMinute", data);
  },
  getAllProgramMinutesByProgramOrderId: async (programOrderId: number) => {
    return await HttpClient.get<PagedResponseType<ProgramMinutesResult>>(
      `/api/Orders/GetAllProgramMinutesByProgramOrderId?programOrderId=${programOrderId}`,
    );
  },
  // program speeches
  createProgramSpeeches: async (request: CreateProgramSpeechesRequest) => {
    return await HttpClient.post<BaseResponseType<ProgramSpeechesResult>>("/api/Orders/CreateProgramSpeeches", request);
  },
  getAllProgramSpeechesByProgramOrderId: async (programOrderId: number) => {
    return await HttpClient.get<PagedResponseType<ProgramSpeechesResult>>(
      `/api/Orders/GetAllProgramSpeechesByProgramOrderId?programOrderId=${programOrderId}`,
    );
  },
  deleteProgramSpeech: async (id: number) => {
    return await HttpClient.delete<BaseResponseType<ProgramSpeechesResult>>(`/api/Orders/DeleteProgramSpeech?id=${id}`);
  },
  downloadSpeechAsPdf: async (speechId: number) => {
    return await HttpClient.get<AxiosResponse<Blob>>(`/api/Orders/ProgramSpeeches/DownloadPdf/${speechId}`, {
      responseType: "blob",
    });
  },
  downloadSpeechAsDoc: async (speechId: number) => {
    return await HttpClient.get<AxiosResponse<Blob>>(`/api/Orders/ProgramSpeeches/DownloadDoc/${speechId}`, {
      responseType: "blob",
    });
  },
  // additional attachments
  getAllAdditionalAttachmentsByProgramOrderId: async (programOrderId: number) => {
    return await HttpClient.get<PagedResponseType<AdditionalAttachmentsResult>>(
      `/api/Orders/GetAllAdditionalAttachmentsByProgramOrderId?programOrderId=${programOrderId}`,
    );
  },
  createAdditionalAttachment: async (request: CreateAdditionalAttachmentRequest) => {
    return await HttpClient.post<BaseResponseType<AdditionalAttachmentsResult>>(
      "/api/Orders/CreateAdditionalAttachment",
      request,
    );
  },
  // pdf templates
  getAllPdfTemplates: async (data: DataGridRequest) => {
    return await HttpClient.post<BaseResponseType<PdfTemplateLocalResult>>("/api/Orders/GetAllPdfTemplates", data);
  },
  getOrderUsersByProgramId: async (programId: number, data: DataGridRequest) => {
    return await HttpClient.post<PagedResponseType<UserProgramOrdersResult>>(
      `/api/Orders/GetOrderUsersByProgramId?programId=${programId}`,
      data,
    );
  },
  getAllMyProgramsOrder: async (data: DataGridRequest) => {
    return await HttpClient.post<PagedResponseType<GetAllMyProgramsOrderResult>>(
      "/api/Orders/getAllMyProgramsOrder",
      data,
    );
  },
  getAllMyProgramsOrderSignedStatus: async (data: DataGridRequest) => {
    return await HttpClient.post<PagedResponseType<GetAllMyProgramsOrderSignedStatusResult>>(
      "/api/Orders/GetAllMyProgramsOrderSignedStatus",
      data,
    );
  },
  exportMyProgramsOrderToExcel: async (data: DataGridRequest) => {
    return await HttpClient.post<AxiosResponse<Blob>>("/api/Orders/ExportMyProgramsOrderToExcel", data, {
      responseType: "blob",
    });
  },
  exportMyProgramsOrderSignedStatusToExcel: async (data: DataGridRequest) => {
    return await HttpClient.post<AxiosResponse<Blob>>("/api/Orders/ExportMyProgramsOrderSignedStatusToExcel", data, {
      responseType: "blob",
    });
  },
  deleteOrder: async (id: number) => {
    return await HttpClient.delete<BaseResponseType<OrderResult>>(`/api/Orders/DeleteOrder/${id}`);
  },
  getOrderActions: async (orderId: number) => {
    return await HttpClient.get<BaseResponseType<OrderActionResult[]>>(
      `/api/Orders/GetAllProgramOrderActionsByOrderId?orderId=${orderId}`,
    );
  },
  updateProgramEvaluationOrder: async (programOrderEvaluationId: number, data: UpdateProgramEvaluationOrderRequest) => {
    return await HttpClient.put<BaseResponseType<any>>(
      `/api/Orders/UpdateProgramEvaluationOrder?programOrderEvaluationId=${programOrderEvaluationId}`,
      data,
    );
  },
};
