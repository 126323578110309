import { useTranslation } from "react-i18next";
import { UserInformationResult } from "services/accountService/models/AccountType";

const ProfessionalInfo = ({ user }: { user?: UserInformationResult | null }) => {
  const { t } = useTranslation("UserProfile");

  return (
    <div className="striped d-flex flex-column ">
      <div className="d-flex flex-1 py-4 pe-4">
        <div className="flex-1">{t("job")}</div>
        <div className="flex-2">{user?.job}</div>
      </div>

      <div className="d-flex flex-1 py-4 pe-4">
        <div className="flex-1">{t("specialization")}</div>
        <div className="flex-2">{user?.specialization}</div>
      </div>

      <div className="d-flex flex-1 py-4 pe-4">
        <div className="flex-1">{t("university")}</div>
        <div className="flex-2">{user?.university}</div>
      </div>

      <div className="d-flex flex-1 py-4 pe-4">
        <div className="flex-1">{t("college")}</div>
        <div className="flex-2">{user?.college}</div>
      </div>

      <div className="d-flex flex-1 py-4 pe-4">
        <div className="flex-1">{t("department")}</div>
        <div className="flex-2">{user?.department}</div>
      </div>

      <div className="d-flex flex-1 py-4 pe-4">
        <div className="flex-1">{t("degree")}</div>
        <div className="flex-2">{user?.degree}</div>
      </div>
    </div>
  );
};

export default ProfessionalInfo;
