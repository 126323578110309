import { useEffect, useMemo, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { OrderLocalResult } from "services/orders/models/OrdersTypes";
import { useTranslation } from "react-i18next";
import { useShallow } from "zustand/react/shallow";
import GridView from "components/GridView";
import useGridStore from "components/GridView/grid.store";
import { OrdersService } from "services/orders";
import { useNotification } from "hooks/useNotification";
import { GridSchema, GridViewHeaderProps } from "components/GridView/GridView.types";
import useSystemDateTheme from "hooks/useSystemDateTheme";
import useFileDownload from "hooks/useFileDownload";
import { useBreadcrumb } from "contexts/breadcrumb/BreadcrumbContext";
import { useCookieContext } from "contexts";

type OrderGridViewColumns =
  | "id"
  | "programName"
  | "appliedUserName"
  | "researcherClassification"
  | "createdOn"
  | "centreManagementDetail"
  | "orderPhase"
  | "centreManagerName"
  | "orderStatus";

export default function OrderGridView({
  userId,
  columnsToHide,
  programId,
}: {
  userId?: string;
  columnsToHide?: OrderGridViewColumns[];
  programId?: number;
}) {
  const { setBreadcrumbs } = useBreadcrumb();
  const { t } = useTranslation(["Orders"]);
  const { toSystemThemeDateFormat } = useSystemDateTheme();
  const { showNotification } = useNotification();
  const downloadFile = useFileDownload();
  const { isAr } = useCookieContext();

  const ready = useRef(false);
  const [loading, setLoading] = useState(false);
  const [orders, setOrders] = useState<OrderLocalResult[] | undefined>();
  const [totalCount, setTotalCount] = useState(0);

  const { sortState, pagination, setPagination, searchValue, filters, resetGridStore } = useGridStore(
    useShallow((state) => ({
      sortState: state.sortState,
      pagination: state.pagination,
      setPagination: state.setPagination,
      searchValue: state.generalSearch,
      filters: state.filters,
      resetGridStore: state.reset,
    })),
  );

  const fetchOrders = async ({ resetPagination = false }: { resetPagination?: boolean } = {}) => {
    try {
      setLoading(true);
      const response = await OrdersService.getAllOrdersAsync({
        pageIndex: resetPagination ? 1 : pagination.currentPage,
        pageSize: 10,
        search: searchValue,
        sort: sortState
          ? Object.keys(sortState).map((key) => ({
              field: key,
              dir: sortState[key],
            }))
          : [],
        filter: [
          ...Object.keys(filters)
            .filter((key) => filters[key])
            .map((key) => ({
              field: key,
              value: filters[key],
              operator: "Contains",
            })),
          ...(programId
            ? [
                {
                  field: "ProgramId",
                  operator: "equal",
                  value: programId,
                },
              ]
            : []),
          ...(userId
            ? [
                {
                  field: "AppliedUserId",
                  operator: "equal",
                  value: userId,
                },
              ]
            : []),
        ],
      });

      setOrders(response.data.result);
      setTotalCount(response.data.totalCount);

      if (resetPagination) {
        setPagination({
          currentPage: 1,
          totalPages: Math.ceil(response.data.totalCount / 10),
        });
      }
    } catch (error) {
      showNotification({
        type: "error",
        description: t("Common:internalServerError"),
      });
      setPagination({
        currentPage: 1,
        totalPages: 0,
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!ready.current) {
      return;
    }
    fetchOrders();
  }, [pagination.currentPage]);

  useEffect(() => {
    if (!ready.current) {
      return;
    }
    fetchOrders({ resetPagination: true });
  }, [sortState, searchValue, filters, isAr]);

  useEffect(() => {
    if (!ready.current) {
      resetGridStore();
      ready.current = true;
      return;
    }
  }, [resetGridStore]);

  useEffect(() => {
    setBreadcrumbs([{ localizationKey: "allOrders", path: "/home/orders", menuItemId: "allOrders" }]);
  }, []);

  const gridSchema = useMemo<GridSchema[]>(
    () => [
      {
        accessor: "id",
        field: "id",
        displayName: t("orderNumber"),
        sortable: true,
        render: (row: OrderLocalResult) => {
          return <Link to={`/home/orders/view/${row.id}`}>{row.serialNumber}</Link>;
        },
      },
      {
        field: "orderDetail.programName",
        displayName: t("programName"),
        sortable: false,
        filterable: true,
        isHidden: columnsToHide?.includes("programName"),
        render(row: OrderLocalResult) {
          return <>{row.programDetail.displayName}</>;
        },
      },
      {
        accessor: "appliedUserName",
        field: "appliedUserName",
        displayName: t("applicantName"),
        isHidden: columnsToHide?.includes("appliedUserName"),
        sortable: false,
        filterable: true,
      },
      {
        accessor: "researcherClassification",
        field: "researcherClassification",
        displayName: t("researcherClassification"),
        sortable: false,
      },
      {
        field: "createdOn",
        displayName: t("submissionDate"),
        sortable: true,
        filterable: true,
        filterOptions: {
          type: "date",
        },
        render(row: OrderLocalResult) {
          return <>{toSystemThemeDateFormat(row.createdOn)}</>;
        },
      },
      {
        field: "centreManagementDetail.displayName",
        displayName: t("researchesCenter"),
        sortable: false,
        filterable: true,
        render(row: OrderLocalResult) {
          return <>{row.centreManagementDetail.displayName}</>;
        },
      },
      {
        field: "orderPhase",
        displayName: t("orderStage"),
        sortable: false,
        filterable: true,
      },
      {
        field: "centreManagerName",
        displayName: t("responsibleParty"),
        sortable: false,
        filterable: true,
      },
      {
        field: "orderStatus",
        displayName: t("orderStatus"),
        sortable: false,
        filterable: true,
        filterOptions: {
          type: "select",
          options: [
            { id: "1", value: t("active") },
            { id: "0", value: t("inactive") },
          ],
        },
        render(row: OrderLocalResult) {
          // TODO: color based on status (primary, success, danger)
          const statusClassName =
            row.orderStatus === "Completed" ? "bg-success text-success " : "bg-primary text-primary";
          return <span className={`badge bg-opacity-10  rounded-4 ${statusClassName} py-2`}>{t(row.orderStatus)}</span>;
        },
      },
    ],
    [columnsToHide, t, toSystemThemeDateFormat],
  );

  const viewHeader: GridViewHeaderProps = {
    title: t("allOrders"),
    singularName: t("order"),
    addPageRoute: "/home/orders/add",
    totalCount: totalCount,
    hideAddButton: true,
    onExportClick: async () => {
      setLoading(true);
      const response = await OrdersService.exportToExcel({
        pageIndex: pagination.currentPage,
        pageSize: 10,
        search: searchValue,
      });
      await downloadFile(response, "orders.xlsx");
      setLoading(false);
    },
  };

  return (
    <div className="bg-white rounded-4 h-100">
      <GridView
        loading={loading}
        gridProps={{
          data: orders ?? [],
          gridSchema,
        }}
        viewHeaderProps={viewHeader}
      />
    </div>
  );
}
