import { useEffect, useMemo, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useShallow } from "zustand/react/shallow";
import GridView from "components/GridView";
import useGridStore from "components/GridView/grid.store";
import { useNotification } from "hooks/useNotification";
import { GridSchema, GridViewHeaderProps } from "components/GridView/GridView.types";
import useSystemDateTheme from "hooks/useSystemDateTheme";
import ActionModal from "components/blocks/Modal/ActionModal";
import { OrdersService } from "services/orders";
import { GetAllMyProgramsOrderResult } from "services/orders/models/OrdersTypes";
import { OrderStatus } from "shared/lookups";
import IconButton from "components/blocks/IconButton";
import useFileDownload from "hooks/useFileDownload";
import { useBreadcrumb } from "contexts/breadcrumb/BreadcrumbContext";
import { useCookieContext } from "contexts";

export const myWorkStatusClassNames: Record<number, string> = {
  [OrderStatus.Completed]: "bg-success bg-opacity-10 text-success",
  [OrderStatus.Inprogress]: "bg-primary bg-opacity-10 text-primary",
  [OrderStatus.Unacceptable]: "bg-danger bg-opacity-10 text-danger",
  [OrderStatus.Draft]: "bg-primary bg-opacity-10 text-primary",
};

export default function MyUnsignedWorkGridView() {
  const { t } = useTranslation("OrdersUnsignedWork");
  const ready = useRef(false);
  const { toSystemThemeDateFormat } = useSystemDateTheme();
  const downloadFile = useFileDownload();
  const { setBreadcrumbs } = useBreadcrumb();

  const [modalProps, setModalProps] = useState({
    isOpen: false,
    type: "delete",
    orderNumber: "",
    id: 0,
    loading: false,
  });
  const [loading, setLoading] = useState(false);
  const { showNotification } = useNotification();
  const [data, setData] = useState<GetAllMyProgramsOrderResult[] | undefined>();
  const [totalCount, setTotalCount] = useState(0);
  const { isAr } = useCookieContext();

  const { sortState, pagination, setPagination, searchValue, filters, resetGridStore } = useGridStore(
    useShallow((state) => ({
      sortState: state.sortState,
      pagination: state.pagination,
      setPagination: state.setPagination,
      searchValue: state.generalSearch,
      filters: state.filters,
      resetGridStore: state.reset,
    })),
  );

  const fetchData = async ({ resetPagination = false }: { resetPagination?: boolean } = {}) => {
    try {
      setLoading(true);
      const response = await OrdersService.getAllMyProgramsOrder({
        pageIndex: resetPagination ? 1 : pagination.currentPage,
        pageSize: 10,
        search: searchValue,
        sort: sortState
          ? Object.keys(sortState).map((key) => ({
              field: key,
              dir: sortState[key],
            }))
          : [],
        filter: Object.keys(filters)
          .filter((key) => filters[key])
          .map((key) => ({
            field: key,
            value: filters[key],
            operator: "Contains",
          })),
      });

      setData(response.data.result);
      setTotalCount(response.data.totalCount);

      if (resetPagination) {
        setPagination({
          currentPage: 1,
          totalPages: Math.ceil(response.data.totalCount / 10),
        });
      }
    } catch (error) {
      showNotification({
        type: "error",
        description: t("Common:internalServerError"),
      });
      setPagination({
        currentPage: 1,
        totalPages: 0,
      });
    } finally {
      setLoading(false);
    }
  };

  const deleteOrderConfirmed = async () => {
    try {
      setModalProps({ ...modalProps, loading: true });
      await OrdersService.deleteOrder(modalProps.id);
      showNotification({
        type: "success",
        description: t("orderDeletedSuccessfully"),
      });

      fetchData({ resetPagination: true });
    } catch (error) {
      showNotification({
        type: "error",
        description: t("Common:internalServerError"),
      });
    } finally {
      setModalProps({
        isOpen: false,
        type: "delete",
        orderNumber: "",
        id: 0,
        loading: false,
      });
    }
  };

  useEffect(() => {
    if (!ready.current) {
      return;
    }
    fetchData();
  }, [pagination.currentPage]);

  useEffect(() => {
    if (!ready.current) {
      return;
    }
    fetchData({ resetPagination: true });
  }, [sortState, searchValue, filters, isAr]);

  useEffect(() => {
    if (!ready.current) {
      resetGridStore();
      ready.current = true;
      return;
    }
  }, [resetGridStore]);

  useEffect(() => {
    setBreadcrumbs([
      {
        localizationKey: "allUnsignedOrders",
        path: "/home/orders/researcherOrders/allUnsignedOrders",
        menuItemId: "allUnsignedOrders",
      },
    ]);
  }, []);

  const gridSchema = useMemo<GridSchema[]>(
    () => [
      {
        field: "ProgramSerialNumber",
        displayName: t("programNumber"),
        render: (row: GetAllMyProgramsOrderResult) => {
          // TODO: send the row id instead once the route and backend are ready
          return (
            <Link to={`/home/orders/researcherOrders/allUnsignedOrders/${row.programId}`}>
              {row.programSerialNumber}
            </Link>
          );
        },
      },
      {
        field: "ProgramDetail.DisplayName",
        displayName: t("programName"),
        render: (row: GetAllMyProgramsOrderResult) => {
          return row.programDetail.displayName;
        },
      },
      {
        field: "OrderSerialNumber",
        accessor: "orderSerialNumber",
        displayName: t("orderNumber"),
      },
      {
        field: "CreatedOn",
        accessor: "createdOn",
        displayName: t("submissionDate"),
        filterable: true,
        filterOptions: {
          type: "date",
        },
        sortable: true,
        render: (row: GetAllMyProgramsOrderResult) => {
          return toSystemThemeDateFormat(row.createdOn);
        },
      },
      {
        field: "orderStatus",
        displayName: t("orderStatus"),
        render(row: GetAllMyProgramsOrderResult) {
          return (
            <span
              className={`badge rounded-4 ${
                myWorkStatusClassNames[row.orderStatusId] ?? "bg-secondary bg-opacity-10 text-secondary"
              } py-2`}
            >
              {myWorkStatusClassNames[row.orderStatusId]
                ? t(`Common:${row.orderStatus?.toLocaleLowerCase()}`)
                : row.orderStatus}
            </span>
          );
        },
      },
      {
        field: "orderPhase",
        displayName: t("orderPhase"),
      },
      {
        field: "FinalCommitteeDetermination",
        accessor: "finalCommitteeDetermination",
        displayName: t("committeeFinalDecision"),
      },
      {
        field: "actions",
        displayName: t("Common:actions"),
        render: (row: GetAllMyProgramsOrderResult) => {
          return row.orderStatusId !== OrderStatus.Draft ? null : (
            <div className={"d-flex gap-1 "}>
              <Link to={`/home/orders/edit/${row.id}`}>
                <IconButton
                  icon="icon-edit"
                  size="md"
                  className="btn action-btn btn-sm rounded p-2"
                  variant="light"
                  innerIconColor="secondary"
                  fitToIconWidth
                />
              </Link>

              <IconButton
                icon="icon-delete"
                onClick={() => {
                  setModalProps({
                    isOpen: true,
                    type: modalProps.type,
                    orderNumber: row.orderSerialNumber,
                    id: row.id,
                    loading: false,
                  });
                }}
                size="md"
                title={t("delete")}
                className="btn action-btn btn-sm rounded p-2"
                variant="light"
                innerIconColor="danger"
                fitToIconWidth
              />
              {/*<i className="icon-delete"  title={t("delete")} />*/}
            </div>
          );
        },
      },
    ],
    [t, toSystemThemeDateFormat],
  );

  const onExportClicked = async () => {
    setLoading(true);
    try {
      const response = await OrdersService.exportMyProgramsOrderToExcel({
        pageIndex: pagination.currentPage,
        pageSize: 10,
        search: searchValue,
      });

      await downloadFile(response, "myUnsignedWork.xlsx");
      setLoading(false);
    } catch (error) {
      showNotification({
        type: "error",
        description: t("Common:internalServerError"),
      });
      setLoading(false);
    }
  };

  const viewHeader: GridViewHeaderProps = {
    title: t("allOrders"),
    singularName: t("order"),
    totalCount: totalCount,
    onExportClick: onExportClicked,
  };

  return (
    <>
      <GridView
        loading={loading}
        gridProps={{
          data: data ?? [],
          gridSchema,
        }}
        viewHeaderProps={viewHeader}
      />

      <ActionModal
        isOpen={modalProps.isOpen}
        onClose={() => {
          setModalProps({
            isOpen: false,
            type: "delete",
            orderNumber: "",
            id: 0,
            loading: false,
          });
        }}
        headerMsg={t("Common:areYouSure")}
        subMsg={t("deleteOrderConfirmationMsg", {
          orderNumber: modalProps.orderNumber,
        })}
        actionIcon="icon-delete"
        confirmBtnText={t("Common:delete")}
        iconWrapperColor={"danger"}
        onActionConfirm={deleteOrderConfirmed}
        loading={modalProps.loading}
      />
    </>
  );
}
