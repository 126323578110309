import Modal, { ModalProps } from "components/blocks/Modal";
import useMutation from "hooks/useMutation";
import { FormCheck } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { OrdersService } from "services/orders";
import { SendToFinanceUnitRequest } from "services/orders/models/OrdersTypes";
import { BaseResponseType } from "services/shared-models/sharedTypes";

const SendOrderToFundingUnitModal: React.FC<
  ModalProps & {
    serialGuid?: string;
    orderId: number;
  }
> = (props) => {
  const { isOpen, onClose, serialGuid, orderId } = props;
  const { t } = useTranslation("Orders");
  const navigate = useNavigate();

  const { handleSubmit, setValue, control } = useForm<SendToFinanceUnitRequest>({
    defaultValues: {
      actionName: "Reject",
      isAccept: false,
      recommendations: "",
      serialGuid: serialGuid,
    },
  });

  const { mutateAsync, loading } = useMutation({
    queryFn: async ({ programOrderId, data }: { programOrderId: number; data: SendToFinanceUnitRequest }) => {
      return await OrdersService.sendToFinanceUnit(programOrderId, data);
    },
  });

  const onSubmit = async (values: SendToFinanceUnitRequest) => {
    values.serialGuid = serialGuid!;
    values.actionName = values.isAccept ? "Approve" : "Reject";
    const res = (await mutateAsync({ programOrderId: orderId, data: values })) as BaseResponseType<any>;
    if (!res.hasError) {
      navigate("/home/tasks");
      onClose?.();
    }
  };

  const onCancel = () => {
    onClose?.();
  };

  return (
    <Modal
      containerClassName="bg-white pb-2"
      isOpen={isOpen}
      onClose={onClose}
      title={t("sendToTheFundingUnit")}
      withHeaderBorder
      size="lg"
      titleClassName="fs-6 text-dark"
    >
      <form noValidate onSubmit={handleSubmit(onSubmit)}>
        <div className="d-flex gap-4">
          <div className="d-flex gap-2">
            <FormCheck
              id="accept-radio"
              type="radio"
              onChange={(value) => {
                setValue("isAccept", true);
              }}
            />
            <label className="form-label" htmlFor="accept-radio">
              {t("approve")}
            </label>
          </div>
          <div className="d-flex gap-2">
            <FormCheck
              id="reject-radio"
              type="radio"
              onChange={(value) => {
                setValue("isAccept", false);
              }}
            />
            <label className="form-label" htmlFor="reject-radio">
              {t("reject")}
            </label>
          </div>
        </div>

        <label className="form-label mt-2">{t("recommendations")}</label>
        <textarea
          className={`form-control`}
          rows={5}
          placeholder={t("recommendations")}
          onChange={(e) => setValue("recommendations", e.target.value)}
          style={{ resize: "none" }}
        />

        <div className="divider my-3"></div>

        <div className="d-flex justify-content-center gap-2 w-100">
          <button type="button" className="btn text-dark rounded-3 p-10-32P" onClick={onCancel}>
            {t("Common:cancel")}
          </button>
          <Controller
            name="recommendations"
            control={control}
            render={({ field }) => (
              <button className="btn btn-primary rounded-3 p-10-32P" disabled={loading || !field.value?.length}>
                {!loading ? t("send") : <div className="custom-loader"></div>}
              </button>
            )}
          />
        </div>
      </form>
    </Modal>
  );
};

export default SendOrderToFundingUnitModal;
