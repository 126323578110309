import ActionModal from "components/blocks/Modal/ActionModal";
import useMutation from "hooks/useMutation";
import { Dispatch, SetStateAction, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ExaminersService } from "services/examiners";
import { ExaminerOrderEvaluationFormResult } from "services/examiners/Model/ExaminerTypes";

type AcceptButtonProps = {
  t: any;
  examinerProgramOrderId: number;
  setData: Dispatch<SetStateAction<ExaminerOrderEvaluationFormResult | null>>;
};

const AcceptButton = ({ t, examinerProgramOrderId, setData }: AcceptButtonProps) => {
  const navigate = useNavigate();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const { data, loading, mutateAsync, reset } = useMutation({
    queryFn: async (examinerProgramOrderId: number) => {
      return await ExaminersService.acceptExaminerOrderEvaluationForm(examinerProgramOrderId);
    },
  });

  const accept = async () => {
    const res = await mutateAsync(examinerProgramOrderId);
    setData((old) => (old ? { ...old, examinerStatus: "Accepted" } : old));
    setIsModalOpen(!!res?.hasError);
  };

  return (
    <>
      {loading ? (
        <div className="custom-loader"></div>
      ) : (
        <button type="button" className="btn btn-primary px-4" onClick={accept}>
          {t("accept")}
        </button>
      )}
      <ActionModal
        isOpen={isModalOpen}
        iconWrapperColor="warning"
        actionIcon="icon-warning-circle"
        headerMsg={t("bankingInformationNotCompleted")}
        subMsg={t("bankingInformationNotCompletedMsg")}
        confirmBtnText={t("goToProfile")}
        loading={loading}
        onActionConfirm={() => {
          navigate("/profile"); // TODO: navigate to the profile page
        }}
        onClose={() => {
          reset();
          setIsModalOpen(false);
        }}
      />
    </>
  );
};

export default AcceptButton;
