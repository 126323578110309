import ApplicantInfo from "./components/ApplicantInfo";
import Updates from "./components/Updates";
import AchievementFile from "./components/AchievementFile";
import FundingTerms from "./components/FundingTerms";
import ResearchesCenter from "./components/ResearchesCenter";
import Procedures from "./components/Procedures";
import { OrderLocalItemResult } from "services/orders/models/OrdersTypes";
import ExaminersSection from "./components/ExaminersSection";
import { OrderUserTypes } from "../..";

const sections = {
  applicantInfo: "applicantInfo",
  updates: "updates",
  achievementFile: "achievementFile",
  fundingTerms: "fundingTerms",
  researchesCenter: "researchesCenter",
  procedures: "procedures",
  examinerSection: "examinerSection",
};

const getActiveSectionsIds = (userType: keyof typeof OrderUserTypes) => {
  if (userType === OrderUserTypes.CentralManagement) {
    return [sections.applicantInfo, sections.examinerSection, sections.procedures];
  }

  return [
    sections.applicantInfo,
    sections.updates,
    sections.achievementFile,
    sections.fundingTerms,
    sections.researchesCenter,
    sections.procedures,
  ];
};

export const OrderInfoTab = ({
  order,
  programFormBuilderSchema,
  userType,
}: {
  order: OrderLocalItemResult | null;
  programFormBuilderSchema?: string;
  userType: keyof typeof OrderUserTypes;
}) => {
  const activeSectionsIds = getActiveSectionsIds(userType);

  return (
    <div className="position-relative">
      {activeSectionsIds.map((sectionId) => {
        switch (sectionId) {
          case sections.applicantInfo:
            return (
              <ApplicantInfo
                key={sectionId}
                appliedUser={order?.appliedUser}
                files={order?.files}
                formData={{
                  subject: order?.formSubject ?? "",
                  value: order?.formValue ?? "{}",
                  schema: programFormBuilderSchema,
                }}
              />
            );
          case sections.updates:
            return <Updates key={sectionId} />;
          case sections.achievementFile:
            return <AchievementFile key={sectionId} />;
          case sections.fundingTerms:
            return <FundingTerms key={sectionId} />;
          case sections.researchesCenter:
            return <ResearchesCenter key={sectionId} />;
          case sections.procedures:
            return <Procedures key={sectionId} orderId={order?.id ?? 0} />;
          case sections.examinerSection:
            return (
              <ExaminersSection
                orderId={order?.id ?? 0}
                programOrderEvaluation={order?.programOrderEvaluation}
                key={sectionId}
              />
            );
          default:
            return null;
        }
      })}
    </div>
  );
};
