import IconButton from "components/blocks/IconButton";
import { t } from "i18next";
import { Link } from "react-router-dom";
import { OrderStatus } from "shared/lookups";
import { TapsIds, tabsIds } from ".";
import { OpenFundingTaskResult } from "services/tasks/models/tasksTypes";
import { OrderLocalItemResult } from "services/orders/models/OrdersTypes";
import { useTranslation } from "react-i18next";

const PageActions = ({
  selectedTabId,
  order,
  openedTask,
  programOrderTaskState,
  onActionClicked,
  onSave,
}: {
  selectedTabId: string;
  order: OrderLocalItemResult | null;
  openedTask: OpenFundingTaskResult | null;
  programOrderTaskState:
    | {
        actions: string[];
        canReturn: boolean;
        orderId: number;
      }
    | undefined;
  onActionClicked: (action: "return" | "respond") => void;
  onSave: (selectedTabId: TapsIds) => void;
}) => {
  const { t } = useTranslation(["Orders", "Shared"]);

  return (
    <>
      {[tabsIds.programMinutesTab, tabsIds.speechesTab, tabsIds.additionalAttachmentsTab].includes(
        selectedTabId as TapsIds,
      ) && (
        <button
          key="save"
          type="submit"
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            onSave(selectedTabId as TapsIds);
          }}
          className="btn btn-primary btn-sm rounded"
        >
          {t("save")}
        </button>
      )}
      {selectedTabId === tabsIds.orderInfoTab && programOrderTaskState && (
        <>
          {/* {order?.isInspectMode && programOrderTaskState.actions.length > 0 && ( */}
          <button className="btn btn-primary px-3" onClick={() => onActionClicked("respond")}>
            {t("sendToTheFundingUnit")}
          </button>
          {/* )} */}
          {programOrderTaskState.canReturn && (
            // TODO: check the other ''return'' type - check figma
            <button
              className="btn btn-outline-secondary d-flex align-items-center gap-2 px-3"
              onClick={() => onActionClicked("return")}
            >
              <i className="icon-turn-order" />
              {t("returnOrder")}
            </button>
          )}
        </>
      )}
      {order?.orderStatusId === OrderStatus.Draft && (
        <>
          <Link to={`/home/orders/edit/${openedTask?.orderId}`}>
            <IconButton
              key="edit"
              icon="icon-edit"
              size="md"
              className="btn action-btn btn-sm rounded p-2"
              variant="light"
              innerIconColor="secondary"
            />
          </Link>
          <IconButton
            key="delete"
            icon="icon-delete"
            size="md"
            className="btn action-btn btn-sm rounded p-2"
            variant="light"
            innerIconColor="danger"
          />
        </>
      )}
    </>
  );
};
export default PageActions;
