import { GridSchema } from "components/GridView/GridView.types";
import Grid from "components/GridView/components/Grid";
import IconButton from "components/blocks/IconButton";
import LoadingOverlay from "components/blocks/LoadingOverlay";
import ActionModal from "components/blocks/Modal/ActionModal";
import { useCookieContext } from "contexts";
import useFileDownload from "hooks/useFileDownload";
import useMutation from "hooks/useMutation";
import { useQuery } from "hooks/useQuery";
import useSystemDateTheme from "hooks/useSystemDateTheme";
import { useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { OrdersService } from "services/orders";
import { ProgramSpeechesResult } from "services/orders/models/OrdersTypes";

const SpeechesTable = ({
  programOrderId,
  createdSpeech,
}: {
  programOrderId: number;
  createdSpeech: ProgramSpeechesResult | undefined;
}) => {
  const { t } = useTranslation("Orders");
  const downloadFile = useFileDownload();

  const selectedSpeechToDownload = useRef<number | null>(null);
  const { toSystemThemeDateFormat } = useSystemDateTheme();
  const { isAr } = useCookieContext();
  const [modalProps, setModalProps] = useState({
    isOpen: false,
    title: "",
    id: 0,
  });

  const {
    data: deleteRes,
    mutateAsync: deleteSpeech,
    loading: deleteSpeechLoading,
  } = useMutation({
    queryFn: async (programOrderId: number) => {
      return await OrdersService.deleteProgramSpeech(programOrderId);
    },
  });

  const { data, loading } = useQuery({
    queryFn: async () => {
      return await OrdersService.getAllProgramSpeechesByProgramOrderId(programOrderId);
    },
    options: {
      enabled: !!programOrderId,
    },
    triggers: [createdSpeech, deleteRes, isAr],
  });

  const { loading: isDownloadTemplateLoading, mutateAsync } = useMutation({
    queryFn: async ({ id, isPdf }: { id: number; isPdf: boolean }) => {
      const res = isPdf ? await OrdersService.downloadSpeechAsPdf(id) : await OrdersService.downloadSpeechAsDoc(id);
      await downloadFile(res, isPdf ? "speechTemplate.pdf" : "speechTemplate.docx");
      selectedSpeechToDownload.current = null;
    },
  });

  const speechTableHeaders = useMemo<GridSchema[]>(() => {
    return [
      {
        accessor: "id",
        field: "id",
        displayName: t("speechNumber"),
      },
      {
        accessor: "pdfTemplateDetail.displayName",
        field: "pdfTemplateDetail.displayName",
        displayName: t("speechName"),
        render: (row) => <>{row.pdfTemplateDetail.displayName}</>,
      },
      {
        accessor: "description",
        field: "description",
        displayName: t("speechDescription"),
      },
      {
        accessor: "createdBy",
        field: "createdBy",
        displayName: t("createdBy"),
      },
      {
        accessor: "createdOn",
        field: "createdOn",
        displayName: t("date"),
        render: (row) => <>{toSystemThemeDateFormat(row.createdOn)}</>,
      },
      {
        field: "speech",
        displayName: t("speech"),
        render(row: ProgramSpeechesResult) {
          const isLoading = isDownloadTemplateLoading || selectedSpeechToDownload.current === row.id;
          return isLoading ? (
            <div className="custom-loader"></div>
          ) : (
            <div className="d-flex gap-2">
              <IconButton
                icon="icon-pdf-big"
                size="md"
                fitToIconWidth
                onClick={async () => {
                  selectedSpeechToDownload.current = row.id;
                  await mutateAsync({ id: row.id, isPdf: true });
                }}
              />
              <IconButton
                icon="icon-doc-big"
                size="md"
                fitToIconWidth
                onClick={async () => {
                  selectedSpeechToDownload.current = row.id;
                  await mutateAsync({ id: row.id, isPdf: false });
                }}
              />
            </div>
          );
        },
      },
      {
        accessor: "actions",
        field: "actions",
        displayName: t("actions"),
        render(row: ProgramSpeechesResult) {
          return (
            <IconButton
              icon="icon-delete"
              innerIconColor="danger"
              size="lg"
              fitToIconWidth
              onClick={() => setModalProps({ isOpen: true, title: row.pdfTemplateDetail.displayName, id: row.id })}
            />
          );
        },
      },
    ];
  }, [isDownloadTemplateLoading, mutateAsync, t, toSystemThemeDateFormat]);

  return (
    <div className="position-relative">
      <LoadingOverlay visible={loading} />
      <Grid gridSchema={speechTableHeaders} data={data?.result ?? []} />
      <ActionModal
        loading={deleteSpeechLoading}
        isOpen={modalProps.isOpen}
        title={t("deleteSpeech")}
        actionIcon="icon-delete"
        headerMsg={t("deleteSpeechHeaderMsg")}
        subMsg={t("deleteSpeechMsg", {
          speechName: `${modalProps.title}?`,
        })}
        confirmBtnText={t("confirmDeleteSpeech")}
        iconWrapperColor="danger"
        onClose={() => setModalProps({ isOpen: false, title: "", id: 0 })}
        onActionConfirm={async () => {
          await deleteSpeech(modalProps.id);
          setModalProps({ isOpen: false, title: "", id: 0 });
        }}
      />
    </div>
  );
};

export default SpeechesTable;
