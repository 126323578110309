import { GridSchema } from "components/GridView/GridView.types";
import Grid from "components/GridView/components/Grid";
import FormInput from "components/blocks/FormInput";
import Modal from "components/blocks/Modal";
import useMutation from "hooks/useMutation";
import { useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { AccountService } from "services/accountService";
import { UserResult } from "services/orders/models/OrdersTypes";
import { OperatorFilter } from "services/shared-models/sharedEnums";
import { TasksService } from "services/tasks";
import { ReAssignRequest, TaskResult } from "services/tasks/models/tasksTypes";

const ReAssignUserModal = ({
  isOpen,
  onClose,
  task,
}: {
  isOpen: boolean;
  task: TaskResult | null;
  onClose: (isReassigned?: boolean) => void;
}) => {
  const { t, i18n } = useTranslation(["Tasks", "Common"]);

  const reAssignFields = useRef({
    search: "",
    note: "",
  });
  const gridSchema = useMemo<GridSchema[]>(
    () => [
      {
        accessor: "name",
        field: "name",
        displayName: t("userFullName"),
        render: (row: UserResult) => {
          return <>{i18n.language === "ar" ? row.arabicName : row.englishName}</>;
        },
      },
      {
        accessor: "username",
        field: "username",
        displayName: t("userName"),
      },
    ],
    [i18n.language, t],
  );

  const { data, loading, mutateAsync } = useMutation({
    queryFn: async (search: string) => {
      const isEmail = /\S+@\S+\.\S+/.test(search);
      const filterField = isEmail ? "email" : "username";

      return await AccountService.getUsersDropdown({
        filter: [
          {
            field: filterField,
            operator: OperatorFilter.equal,
            value: search,
          },
        ],
        pageSize: 1,
        pageIndex: 1,
      });
    },
  });

  const { loading: reAssignLoading, mutateAsync: reAssignMutateAsync } = useMutation({
    queryFn: async (data: ReAssignRequest) => {
      await TasksService.reAssignTask(data);
    },
  });

  const onSearchForUser = async () => {
    await mutateAsync(reAssignFields.current.search);
  };

  const handleReAssignUser = async () => {
    if (data && data.result.length > 0) {
      await reAssignMutateAsync({
        serialGid: task?.serialGID ?? "",
        note: reAssignFields.current.note,
        toUserName: data.result[0].username,
      });
      onClose(true);
    }
  };

  return (
    <Modal
      title={t("reAssign")}
      isOpen={isOpen}
      onClose={() => onClose()}
      containerClassName="bg-white px-4 pt-4 pb-0"
      size="lg"
      titleClassName="fs-6"
      withHeaderBorder
    >
      <div className="d-flex flex-column gap-3 border-bottom pb-3">
        <div>
          <label>{t("search")}</label>
          <div className="d-flex align-items-end gap-2">
            <FormInput
              wrapperClassName="flex-1"
              leftElement={loading && <div className="custom-loader"></div>}
              className="form-control flex-1"
              placeholder={t("searchByUsername/Email...")}
              disabled={reAssignLoading || loading}
              onInput={(e) => (reAssignFields.current.search = e.currentTarget.value)}
            />
            <button
              className="btn btn-primary p-10-32P rounded-3"
              onClick={onSearchForUser}
              disabled={reAssignLoading || loading}
            >
              {t("search")}
            </button>
          </div>
        </div>

        <FormInput
          className="form-control my-1"
          placeholder={t("note")}
          onInput={(e) => (reAssignFields.current.note = e.currentTarget.value)}
        />

        <Grid data={data?.result ?? []} gridSchema={gridSchema} />
      </div>

      <div className="d-flex justify-content-center mt-3">
        <button className={`btn btn-outline-secondary border rounded-3 p-10-32P mx-2`} onClick={()=> onClose()}>
          {t("Common:cancel")}
        </button>
        <button className="btn btn-primary p-10-32P rounded-3" onClick={handleReAssignUser} disabled={reAssignLoading}>
          {reAssignLoading ? <div className="custom-loader"></div> : t("assign")}
        </button>
      </div>
    </Modal>
  );
};

export default ReAssignUserModal;
