import PageWithTabs from "components/PageWithTabs";
import IconButton from "components/blocks/IconButton";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import Switch from "components/blocks/Switch";
import { ProgramsService } from "services/programs";
import { useNotification } from "hooks/useNotification";
import { useCallback, useEffect, useState } from "react";
import { ProgramByIDResponse } from "services/programs/models/ProgramTypes";
import LoadingOverlay from "components/blocks/LoadingOverlay";
import { useCookieContext } from "contexts";
import AboutProgramTab from "./tabs/AboutProgramTab";
import OrdersTab from "./tabs/OrdersTab";
import ApplicantsTab from "./tabs/ApplicantsTab";
import useSystemDateTheme from "hooks/useSystemDateTheme";
import ActionModal from "components/blocks/Modal/ActionModal";
import { useBreadcrumb } from "contexts/breadcrumb/BreadcrumbContext";

type TapsIds = "about" | "orders" | "applicants";
const tabsIds: { [key in TapsIds]: TapsIds } = {
  about: "about",
  orders: "orders",
  applicants: "applicants",
};

const ViewProgram = () => {
  // Hooks
  const { isAr } = useCookieContext();
  const navigate = useNavigate();
  const { t } = useTranslation("Programs");
  const { programId } = useParams();
  const { showNotification } = useNotification();
  const { toSystemThemeDateFormat } = useSystemDateTheme();
  const { setBreadcrumbs } = useBreadcrumb();

  // State
  const [programData, setProgramData] = useState<ProgramByIDResponse>();
  const [loading, setLoading] = useState(false);
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);

  // Functions
  const fetchProgram = useCallback(async () => {
    if (!programId) {
      showNotification({ description: "programId is required", type: "error" });
      return navigate(-1);
    }
    try {
      setLoading(true);
      const response = await ProgramsService.getProgramById(+programId);
      if (response.hasError) {
        showNotification({ description: response.description, type: "error" });
        navigate(-1);
      } else {
        setProgramData(response.data);
      }
    } catch {
      showNotification({ description: t("Common:internalServerError"), type: "error" });
      // TODO: check if we need to navigate back
      navigate(-1);
    } finally {
      setLoading(false);
    }
  }, []);

  const onDeleteModalConfirm = async () => {
    if (!programId) return;

    const res = await ProgramsService.deleteProgram(+programId);
    if (res.hasError) {
      showNotification({
        type: "error",
        description: t("Common:internalServerError"),
      });
    } else {
      showNotification({
        type: "success",
        description: t("Common:deleteSuccess"),
      });
      setDeleteModalVisible(false);
      navigate("/home/programs");
    }
  };

  // Effects
  useEffect(() => {
    fetchProgram();
  }, [fetchProgram, programId, isAr]);

  useEffect(() => {
    setBreadcrumbs([
      {
        localizationKey: "allPrograms",
        path: "/home/Programs",
      },
      {
        localizationKey: "program",
        path: `/home/programs/view/${programId}`,
        localizationParameters: { programNumber: programData?.serialNumber },
      },
    ]);
  }, [programData]);

  // TODO: get the values instead of ids
  const subTitle = programData
    ? `${t("programNumber")}: ${programData?.serialNumber} • ${t("cycle")}: ${
        programData?.programCycleYearDetail.displayName
      }-${programData?.programCycleDetail.displayName}`
    : "";
  const programName = programData
    ? `${programData?.programDetails.find((x) => x.locale === (isAr ? "Ar" : "En"))?.displayName}`
    : "";

  return (
    <>
      <LoadingOverlay visible={loading} />
      <PageWithTabs
        titleImgSrc={
          programData
            ? `https://apiGateway.aaltair.net/FileManagerApi/File/Download/${programData.adsImageId}`
            : undefined
        }
        title={
          <div className="d-flex gap-2">
            {programName}
            <Switch checked onChange={() => {}} />
          </div>
        }
        subTitle={subTitle}
        defaultTabId={tabsIds.about}
        // TODO: set keep keep tabs mounted to true once grid store is keyed
        // Currently grid store is shared (2 paginated grid cannot be collocated in the same component)        // keepTabsMounted
        pageHeaders={
          !programData
            ? []
            : [
                {
                  title: t("programTypeClass"),
                  // TODO: get the values from backend
                  value: `${programData?.programTypeName} - ${programData?.classificationProgramName}`,
                },
                {
                  title: t("adsDuration"),
                  value: `${toSystemThemeDateFormat(programData.adsStartDate)} - ${toSystemThemeDateFormat(
                    programData.adsEndDate,
                  )}`,
                },
                {
                  title: t("funding"),
                  value: `${programData.fundingDurationInMonths} ${t("months")} - ${programData.fundingAmount}${t(
                    "Common:riyal",
                  )}`,
                },
                {
                  title: t("contractStart"),
                  value: `${toSystemThemeDateFormat(programData.contractStartDate)}`,
                },
                {
                  title: t("programOrders"),
                  value: programData?.numberOfOrders ?? 0,
                },
                {
                  title: t("targetedAudience"),
                  value: programData.audienceTypes
                    .map((audience) => audience.audienceTypeDetail.displayName)
                    .join(", "),
                },
              ]
        }
        actions={(selectedTabId) => {
          return (
            <>
              <IconButton
                icon="icon-share"
                size="md"
                className="btn action-btn btn-sm rounded p-2"
                variant="light"
                innerIconColor="secondary"
                onClick={() => {
                  navigator.clipboard.writeText(
                    `${window.location.protocol}//${window.location.host}/programDetails/${programId}`,
                  );
                  showNotification({ type: "success", description: t("Common:linkCopiedToClipboard") });
                }}
              />
              <IconButton
                icon="icon-edit"
                size="md"
                className="btn action-btn btn-sm rounded p-2"
                variant="light"
                innerIconColor="secondary"
                onClick={() => navigate(`/home/programs/form?id=${programId}`)}
              />
              <IconButton
                icon="icon-delete"
                size="md"
                className="btn action-btn btn-sm rounded p-2"
                variant="light"
                innerIconColor="danger"
                onClick={() => setDeleteModalVisible(true)}
              />
            </>
          );
        }}
        tabs={[
          {
            id: tabsIds.about,
            title: t("aboutProgram"),
            content: programData ? <AboutProgramTab program={programData} /> : null,
          },
          {
            id: tabsIds.orders,
            title: t("orders"),
            content: programData ? (
              <div className="p-4">
                <OrdersTab programId={programData.id} />
              </div>
            ) : null,
          },
          {
            id: tabsIds.applicants,
            title: t("applicants"),
            content: programData ? <ApplicantsTab programId={programData.id} /> : null,
          },
        ]}
      />

      <ActionModal
        isOpen={deleteModalVisible}
        onClose={() => {
          setDeleteModalVisible(false);
        }}
        headerMsg={t("DeleteDialogTitle")}
        subMsg={t("deleteProgramModalSubMsg", {
          ProgramName: programData?.programDetails.find((x) => x.locale === (isAr ? "Ar" : "En"))?.displayName,
        })}
        actionIcon="icon-delete"
        confirmBtnText={t("delete")}
        iconWrapperColor={"danger"}
        onActionConfirm={onDeleteModalConfirm}
      />
    </>
  );
};

export default ViewProgram;
