import IconButton from "components/blocks/IconButton";
import { LegacyRef, forwardRef, useRef, useState } from "react";
import { Popover } from "react-bootstrap";
import "./style.scss";
import { TaskResult } from "services/tasks/models/tasksTypes";
import { OverlayInjectedProps } from "react-bootstrap/esm/Overlay";
import { Omit } from "react-bootstrap/esm/helpers";
import useSystemDateTheme from "hooks/useSystemDateTheme";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { TasksService } from "services/tasks";

const NotificationMenu = forwardRef(
  (
    props: {
      notifications?: TaskResult[] | null;
      loading?: boolean;
      onClose?: () => void;
    } & Omit<OverlayInjectedProps, "ref">,
    ref: LegacyRef<HTMLDivElement> | undefined,
  ) => {
    const { t } = useTranslation(["Shared"]);
    const navigate = useNavigate();
    const { formatRelativeDateWithLocalization } = useSystemDateTheme();
    const { notifications, loading, onClose, className, ...rest } = props;

    const selectedIdRef = useRef("");
    const [isOpenTaskLoading, setIsOpenTaskLoading] = useState(false);

    const onNotificationClicked = async (notification: TaskResult) => {
      if (notification.isOpen || isOpenTaskLoading) return;

      try {
        setIsOpenTaskLoading(true);
        selectedIdRef.current = notification.serialGID;
        const res = await TasksService.openFundingOrderTask(notification.serialGID);
        onClose?.();

        navigate(`/home/orders/view/${res.orderId}`, {
          state: { adminTaskOrderId: res.orderId, actions: res.actionName },
        });

        if (window.location.pathname.includes("/home/orders/view")) {
          navigate(0);
        }
      } catch (error) {
        setIsOpenTaskLoading(false);
      }
    };

    return (
      <Popover
        ref={ref}
        {...rest}
        arrowProps={{
          style: {
            position: "absolute",
            left: "50%",
            transform: "translateX(-50%) rotate(90deg)",
            top: "-16px",
          },
        }}
        className={`${className}  popover-notification-container p-4 pb-0 bg-white rounded-4 custom-shadow border-0 text-dark`}
      >
        <div className="bg-white d-flex justify-content-between align-items-center p-0 pb-2">
          <span className="fs-5 fw-bold">{t("notifications")}</span>
          <IconButton icon="icon-close" fitToIconWidth innerIconColor="dark" size="lg" onClick={() => onClose?.()} />
        </div>
        <div className="divider"></div>
        <Popover.Body className="popover-notification-body p-0 py-2">
          {loading ? (
            <div className="custom-loader"></div>
          ) : !notifications?.length ? (
            <div className="text-center text-dark">{t("noNotifications")}</div>
          ) : (
            notifications?.map((notification, i) => (
              <div
                key={notification.serialGID}
                onClick={() => onNotificationClicked(notification)}
                className={`${!notification.isOpen ? "cursor-pointer " : ""}`}
              >
                <div className="d-flex justify-content-between align-items-center">
                  <div className="d-flex gap-1 align-items-center">
                    {isOpenTaskLoading && selectedIdRef.current === notification.serialGID ? (
                      <div className="custom-loader"></div>
                    ) : (
                      !notification.isOpen && <div className="circle"></div>
                    )}
                    <div className="fw-bold text-dark">{notification.activityName}</div>
                  </div>
                  <span className="text-dark fs-10px">
                    {formatRelativeDateWithLocalization(notification.startDateTime)}
                  </span>
                </div>
                <span className="fs-12px mt-2">{notification.folio}</span>
                {notifications.length - 1 != i && <div className="divider"></div>}
              </div>
            ))
          )}
        </Popover.Body>
      </Popover>
    );
  },
);

export default NotificationMenu;
