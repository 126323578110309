import FileInput from "components/blocks/FileInput";
import FilePreview from "components/blocks/FileInput/FilePreview";
import { SelectedFilesType } from "components/blocks/FileUploadWithPreview";
import useMutation from "hooks/useMutation";
import { UseFormRegister } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { FileService } from "services/fileService";
import { CreateOrderRequest } from "services/orders/models/OrdersTypes";

const FileUploadSection = ({
  register,
  files,
  setFiles,
}: {
  register: UseFormRegister<CreateOrderRequest>;
  files: SelectedFilesType[];
  setFiles: (files: SelectedFilesType[]) => void;
}) => {
  const { t } = useTranslation(["Orders", "Common"]);

  const { mutateAsync: uploadFileAsync, loading: fileUploadLoading } = useMutation({
    queryFn: async (values: File) => {
      return await FileService.uploadFile(values);
    },
  });

  const { mutateAsync: fileDownload, loading: fileDownloadLoading } = useMutation({
    queryFn: async (id: string) => {
      await FileService.downloadFile(id);
      return { hasError: false, data: null, description: "", code: 200 };
    },
  });

  const onFileUpload = async (file: File) => {
    const response = await uploadFileAsync(file);
    if (response && !response.hasError) {
      setFiles([
        ...files,
        {
          fileName: response.data!.fileName,
          uuid: response.data!.uuid,
          file,
          size: `${(file.size / 1024 / 1024).toFixed(2)}MB`,
        },
      ]);
    }
  };

  return (
    <div className="d-flex flex-column gap-1 flex-2">
      <FileInput
        labelClassName="fw-bold"
        label={t("attachments")}
        clickHereForLabel={t("Common:toAttachFile")}
        containerWrapperClassName="flex-grow-1"
        {...register("filesIds")}
        onChange={(files) => {
          files && onFileUpload(files[0]);
        }}
      />
      <div className="d-flex flex-wrap gap-1 align-items-center ">
        {fileUploadLoading && <div className="custom-loader"></div>}
        {files.map((file) => (
          <FilePreview
            key={file.uuid}
            loading={fileDownloadLoading}
            onDelete={() => {
              setFiles(files.filter((f) => f.uuid !== file.uuid));
            }}
            onShow={async () => {
              await fileDownload(file.uuid);
            }}
            file={file.file}
          />
        ))}
      </div>
    </div>
  );
};

export default FileUploadSection;
