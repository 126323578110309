import { useEffect, useMemo, useRef, useState } from "react";
import { useCookieContext } from "../../../contexts";
import { TranslationModel } from "../../../helpers";
import { LoginResult } from "../../../services/users/models/loginResult";
import icon from "../../../assets/image/login/icon.png";
import SettingsDropDown from "./SettingsDropDown";
import { Overlay } from "react-bootstrap";
import NotificationMenu from "./NotificationMenu";
import { useQuery } from "hooks/useQuery";
import { TasksService } from "services/tasks";
import { SortDirection } from "services/shared-models/sharedEnums";
import { useBreadcrumb } from "contexts/breadcrumb/BreadcrumbContext";
import { useTranslation } from "react-i18next";
import "./style.scss";

function Header() {
  const { t } = useTranslation("Shared");
  const { userSession, localization, isAr } = useCookieContext();
  const { breadcrumbs, onItemInPathClicked } = useBreadcrumb();

  const overlayRef = useRef<HTMLButtonElement>(null);
  const [show, setShow] = useState(false);
  const [user, setUser] = useState<LoginResult>();
  const [locale, setLocale] = useState<TranslationModel>();

  useEffect(() => {
    if (localization) {
      setLocale(JSON.parse(localization));
    }
    if (userSession) {
      setUser(JSON.parse(userSession));
    }
  }, [userSession, localization]);

  const { data: notifications, loading } = useQuery({
    queryFn: async () => {
      return await TasksService.getMyTasks({
        pageIndex: 0,
        pageSize: 30,
        sort: [
          {
            field: "isOpen",
            dir: SortDirection.Desc,
          },
        ],
      });
    },
    triggers: [isAr],
  });

  const hasOpenTasks = useMemo(() => {
    return notifications?.result.some((task) => !task.isOpen);
  }, [notifications]);

  return (
    <>
      <div className="header gap-4">
        <div className="right-section gap-4 w-100">
          <a className="home-link" href="/">
            <img src={icon} alt="Home Icon" className="home-icon" />
          </a>

          <div className="text-white">
            <span>
              {breadcrumbs.map((breadcrumb, index) => (
                <span key={index}>
                  <button
                    onClick={() => onItemInPathClicked(index)}
                    className=" btn btn-link text-white text-decoration-none"
                  >
                    {t(`Shared:${breadcrumb.localizationKey}`, breadcrumb.localizationParameters)}
                  </button>
                  {index < breadcrumbs.length - 1 && " > "}
                </span>
              ))}
            </span>
          </div>
        </div>
        <div className="center-section">
          <button className="message-btn">
            <i className={"icon-search-24 text-white fs-24px"} />
          </button>

          <button ref={overlayRef} className="message-btn position-relative" onClick={() => setShow(!show)}>
            <span
              className={`circle position-absolute ${hasOpenTasks ? "bg-danger" : "bg-secondary"} tasks-status-circle`}
            ></span>
            <i className={"icon-bell text-white fs-24px"} />
          </button>
          <Overlay
            show={show}
            rootClose
            onHide={() => {
              setShow(false);
            }}
            placement="bottom"
            offset={[isAr ? 0 : 10, 16]}
            transition
            target={overlayRef.current}
          >
            {({
              placement: _placement,
              arrowProps: _arrowProps,
              show: _show,
              popper: _popper,
              hasDoneInitialMeasure: _hasDoneInitialMeasure,
              ...props
            }) => (
              <NotificationMenu
                {...props}
                notifications={notifications?.result}
                loading={loading}
                onClose={() => {
                  setShow(false);
                }}
              />
            )}
          </Overlay>
        </div>
        <SettingsDropDown
          userName={locale?.CurrentLanguage === "ar" ? user?.arabicName : user?.englishName}
          userId={user?.id}
        />
      </div>
    </>
  );
}

export default Header;
