import PageWithTabs from "components/PageWithTabs";
import { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import OrderFormDetails from "./OrderFormDetails";
import useSystemDateTheme from "hooks/useSystemDateTheme";
import IconButton from "components/blocks/IconButton";
import { useNavigate } from "react-router-dom";
import { useBreadcrumb } from "contexts/breadcrumb/BreadcrumbContext";
import useOrderForm from "./useOrderForm";

export const OrderFormTypes = {
  EditOrder: "EditOrder",
  ReturnedOrder: "ReturnedOrder",
  AddOrder: "AddOrder",
} as const;

export type AddEditFormRef = { submitForm: (isDraft: boolean, orderFormType: keyof typeof OrderFormTypes) => void };

const OrderForm = ({ orderFormType }: { orderFormType: keyof typeof OrderFormTypes }) => {
  const { setBreadcrumbs } = useBreadcrumb();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation(["Orders", "Common"]);
  const { toSystemThemeDateFormat } = useSystemDateTheme();
  const formRef = useRef<AddEditFormRef>(null);
  const { isLoading, orderId, serialGID, order, orderProgram } = useOrderForm({ orderFormType });

  const onSubmit = async (isDraft = false) => {
    if (formRef.current) {
      formRef.current.submitForm(isDraft, orderFormType);
    }
  };

  const onSaveAsDraft = async () => {
    onSubmit(true);
  };

  const programDetails = orderProgram?.programDetails.find(
    (item) => i18n.language === item.locale?.toLocaleLowerCase(),
  );

  const isReturnedOrder = orderFormType === OrderFormTypes.ReturnedOrder;
  useEffect(() => {
    setBreadcrumbs([
      { localizationKey: "allOrders", path: "/home/orders", menuItemId: "allOrders" },
      {
        localizationKey: orderId ? "editOrder" : "addOrder",
        path:
          OrderFormTypes.EditOrder === orderFormType
            ? `/home/orders/edit/${orderId}`
            : isReturnedOrder
            ? `/home/orders/editOrderTask/${serialGID}`
            : "/home/orders/add",
      },
    ]);
  }, []);

  return (
    <PageWithTabs
      loading={isLoading}
      title={
        <div className="d-flex gap-2">
          <span>{programDetails?.displayName ?? ""}</span>
          <IconButton
            icon="icon-view"
            size="lg"
            fitToIconWidth
            innerIconColor="primary"
            iconPosition="start"
            onClick={() => {
              if (!!orderProgram?.id) navigate(`/programDetails/${orderProgram.id}`);
            }}
          />
        </div>
      }
      subTitle={
        <span>
          {t("programNumber")}: {orderProgram?.serialNumber ?? ""} • {t("cycle")}:
          {` ${orderProgram?.programCycleId ?? ""}-${orderProgram?.programCycleYearId ?? ""}`}
        </span>
      }
      withBackButton={false}
      titleImgSrc={orderProgram?.adsImage}
      pageHeaders={[
        {
          title: t("startContract"),
          value: toSystemThemeDateFormat(orderProgram?.contractStartDate),
        },
        {
          title: t("fundingTime"),
          value: orderProgram?.fundingDurationInMonths ?? "" + " " + t("month"),
        },
      ]}
      actions={() => {
        return (
          <>
            <button className="btn btn-primary p-10-32P" onClick={() => onSubmit(false)}>
              {!isReturnedOrder ? t("submitOrder") : t("send")}
            </button>
            {!isReturnedOrder && (
              <button type="button" className="btn border bg-white text-dark p-10-32P" onClick={onSaveAsDraft}>
                {t("saveAsDraft")}
              </button>
            )}
            <button
              type="button"
              className="btn border bg-white text-dark p-10-32P"
              onClick={() => {
                if (!!orderProgram?.id) navigate(`/programDetails/${orderProgram.id}`);
              }}
            >
              {t("Common:cancel")}
            </button>
          </>
        );
      }}
      tapContent={
        <OrderFormDetails
          ref={formRef}
          program={orderProgram}
          order={order}
          orderFormType={orderFormType}
          serialGuid={serialGID}
        />
      }
    />
  );
};

export default OrderForm;
