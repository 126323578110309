import { useEffect, useMemo, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useShallow } from "zustand/react/shallow";
import GridView from "components/GridView";
import useGridStore from "components/GridView/grid.store";
import { ExaminerRewardsService } from "services/examinerRewards";
import { useNotification } from "hooks/useNotification";
import { GridSchema, GridViewHeaderProps } from "components/GridView/GridView.types";
import useSystemDateTheme from "hooks/useSystemDateTheme";
import IconButton from "components/blocks/IconButton";
import { ExaminerRewardsLocalResult } from "services/examinerRewards/Models/ExaminerRewardsTypes";
import Modal from "components/blocks/Modal";
import FormInput from "components/blocks/FormInput";
import Dropdown from "components/blocks/Dropdown";
import FileInput from "components/blocks/FileInput";
import FilePreview from "components/blocks/FileInput/FilePreview";
import { useBreadcrumb } from "contexts/breadcrumb/BreadcrumbContext";

export default function ExaminerRewardsGridView() {
  const { t } = useTranslation(["ExaminerRewards", "Common"]);
  const { toSystemThemeDateFormat } = useSystemDateTheme();
  const { showNotification } = useNotification();
  const { setBreadcrumbs } = useBreadcrumb();

  const [modalProps, setModalProps] = useState({
    isOpen: false,
    status: "",
    amount: 0,
  });

  const [loading, setLoading] = useState(false);
  const [ExaminerRewards, setExaminerRewards] = useState<ExaminerRewardsLocalResult[] | undefined>();
  const [totalCount, setTotalCount] = useState(0);
  const ready = useRef(false);

  const { sortState, pagination, setPagination, searchValue, filters, resetGridStore } = useGridStore(
    useShallow((state) => ({
      sortState: state.sortState,
      pagination: state.pagination,
      setPagination: state.setPagination,
      searchValue: state.generalSearch,
      filters: state.filters,
      resetGridStore: state.reset,
    })),
  );

  const fetchExaminerRewards = async ({ resetPagination = false }: { resetPagination?: boolean } = {}) => {
    try {
      setLoading(true);
      const response = await ExaminerRewardsService.getAllExaminerRewardsAsync({
        pageIndex: resetPagination ? 1 : pagination.currentPage,
        pageSize: 10,
        search: searchValue,
        sort: sortState
          ? Object.keys(sortState).map((key) => ({
              field: key,
              dir: sortState[key],
            }))
          : [],
        filter: Object.keys(filters)
          .filter((key) => filters[key])
          .map((key) => ({
            field: key,
            value: filters[key],
            operator: "Contains",
          })),
      });

      setExaminerRewards(response.data.result);
      setTotalCount(response.data.totalCount);

      if (resetPagination) {
        setPagination({
          currentPage: 1,
          totalPages: Math.ceil(response.data.totalCount / 10),
        });
      }
    } catch (error) {
      showNotification({
        type: "error",
        description: t("Common:internalServerError"),
      });
      setPagination({
        currentPage: 1,
        totalPages: 0,
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!ready.current) {
      return;
    }
    fetchExaminerRewards();
  }, [pagination.currentPage]);

  useEffect(() => {
    if (!ready.current) {
      return;
    }
    fetchExaminerRewards({ resetPagination: true });
  }, [sortState, searchValue, filters]);

  useEffect(() => {
    if (!ready.current) {
      resetGridStore();
      ready.current = true;
      return;
    }
  }, [resetGridStore]);

  const onEditReward = (row: ExaminerRewardsLocalResult) => {
    // showModal
    setModalProps({
      isOpen: true,
      amount: row.amount,
      status: row.status,
    });
  };

  const onModalConfirm = () => {
    // TODO: call service to block/unblock ExaminerReward
    // hideModal
    setModalProps({
      isOpen: false,
      amount: 0,
      status: "",
    });
  };

  useEffect(() => {
    setBreadcrumbs([
      {
        localizationKey: "examinerRewards",
        path: "/home/paymentsAndRewards/examinerRewards",
        menuItemId: "examinerRewards",
      },
    ]);
  }, []);

  const gridSchema = useMemo<GridSchema[]>(
    () => [
      {
        accessor: "examinerName",
        field: "examinerName",
        displayName: t("examinerName"),
        sortable: true,
      },
      {
        field: "orderNumber",
        displayName: t("orderNumber"),
        sortable: true,
        filterable: true,
        render: (row: ExaminerRewardsLocalResult) => {
          return <Link to={`/home/orders/view/${row.orderId}`}>{row.orderNumber}</Link>;
        },
      },
      {
        field: "researchesCenter",
        displayName: t("researchesCenter"),
        sortable: true,
        filterable: true,
        filterOptions: {
          type: "date",
        },
      },
      {
        field: "applicantName",
        displayName: t("applicantName"),
        sortable: true,
        filterable: true,
      },
      {
        field: "rewardConfirmationDate",
        displayName: t("rewardConfirmationDate"),
        sortable: true,
        filterable: true,
        filterOptions: {
          type: "date",
        },
        render(row: ExaminerRewardsLocalResult) {
          return <>{toSystemThemeDateFormat(row.rewardConfirmationDate)}</>;
        },
      },
      {
        field: "amount",
        displayName: t("amount"),
        sortable: true,
        filterable: true,
        render(row: ExaminerRewardsLocalResult) {
          return (
            <span>
              {row.amount} {t("ryal")}
            </span>
          );
        },
      },
      {
        field: "status",
        displayName: t("status"),
        sortable: true,
        filterable: true,
        filterOptions: {
          type: "select",
          options: [
            { id: "1", value: t("unpaid") },
            { id: "0", value: t("paid") },
            { id: "2", value: t("pending") },
          ],
        },
        render(row: ExaminerRewardsLocalResult) {
          const statusClassName =
            row.status === "unpaid"
              ? "bg-danger text-danger"
              : row.status === "paid"
              ? "bg-success text-success"
              : "bg-primary text-primary";
          return <span className={`badge rounded-4 ${statusClassName} bg-opacity-10 py-2`}>{t(row.status)}</span>;
        },
      },
      {
        field: "actions",
        displayName: t("Common:actions"),
        render: (row: ExaminerRewardsLocalResult) => {
          if (row.status === "paid") {
            return null;
          }

          return (
            <IconButton
              icon="icon-edit"
              fitToIconWidth
              innerIconColor="primary"
              size="md"
              onClick={() => onEditReward(row)}
            />
          );
        },
      },
    ],
    [t, toSystemThemeDateFormat],
  );

  const viewHeader: GridViewHeaderProps = {
    title: t("allExaminerRewards"),
    singularName: t("result"),
    totalCount: totalCount,
    hideAddButton: false,
  };

  return (
    <div>
      <GridView
        loading={loading}
        gridProps={{
          data: ExaminerRewards ?? [],
          gridSchema,
        }}
        viewHeaderProps={viewHeader}
      />
      <Modal
        isOpen={modalProps.isOpen}
        onClose={() => setModalProps({ isOpen: false, amount: 0, status: "" })}
        title="اسم الفاحص"
        size="lg"
        containerClassName="bg-white"
        bodyMargin="my-1"
        titleClassName="fs-6"
      >
        <div className="d-flex flex-column gap-3">
          <div className="divider" />

          <div className="d-flex gap-3">
            <FormInput
              wrapperClassName="flex-1"
              label={t("amount")}
              value={modalProps.amount}
              leftElement={<span className="text-primary">{t("ryal")}</span>}
            />
            <Dropdown
              wrapperClassName="flex-1"
              label={t("status")}
              data={[
                { id: "1", value: t("unpaid") },
                { id: "0", value: t("paid") },
                { id: "2", value: t("pending") },
              ]}
            />
          </div>
          <div>
            <FileInput label={t("attachments")} onChange={() => {}} clickHereForLabel={t("toAddBillOfExchange")} />
            <div className="d-flex gap-2 ">
              {[1].map((item) => (
                <FilePreview
                  key={item}
                  file={new File([""], "سند صرف.pdf", { type: "application/pdf" })}
                  onShow={() => {}}
                />
              ))}
            </div>
          </div>

          <div className="divider" />

          <div className="d-flex gap-2 justify-content-center mt-2">
            <button className="p-10-56P btn btn-primary" onClick={onModalConfirm}>
              {t("send")}
            </button>
            <button
              className="p-10-56P btn btn-outline-danger"
              onClick={() => setModalProps({ isOpen: false, amount: 0, status: "" })}
            >
              {t("rejectRewardConfirmation")}
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
}
